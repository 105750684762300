import React, {useEffect} from 'react';
import Layout from "../../Layouts/Layouts";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/diagnos.jpg"
import {Helmet} from "react-helmet";

const CarMechanic = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'Keep your car running smoothly with professional diagnostics and mechanical work from our mechanics. You can be sure you are driving safe and sound.')
    //     let themeMeta=document.querySelector("meta[name='keywords']")
    //     themeMeta.setAttribute("content", "car mechanic, auto mechanic, car diagnostic, auto diagnostic, vehicle diagnostics")
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Car Mechanic Los Angeles - La Mechanic | La West</title>
                <meta name="description"
                      content="Los Angeles auto mechanic experts at LA West Collision deliver top-notch car repairs for a smooth ride. Trust us for reliable automotive service." />
                <meta name="keywords" content="car mechanic, auto mechanic, car diagnostic, auto diagnostic, vehicle diagnostics"/>
                <script type="application/ld+json">
                    {`
                   {
  "@context": "https://schema.org",
  "@type": "Service",
  "provider": {
    "@type": "AutoBodyShop",
    "name": "LA West Collision Center",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "6827 Troost Ave North Hollywood, CA 91605",
      "addressLocality": "Los Angeles",
      "postalCode": "91605",
      "addressCountry": "US"
    },
    "telephone": "(818) 821-6033",
    "url": "https://www.lawestcollision.com",
    "logo": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg",
    "description": "LA West is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details.
"
  },
  "serviceType": "Auto Mechanic",
  "offers": {
    "@type": "Offer",
    "priceCurrency": "USD",
    "url": "https://www.lawestcollision.com/car-mechanic"
  },
  "areaServed": {
    "@type": "City",
    "name": "Los Angeles"
  },
  "description": "Auto Mechanic service encompasses the diagnosis, repair, and maintenance of various vehicle systems, from engines to electronics. Trained technicians ensure optimal performance, longevity, and safety by addressing issues and conducting regular check-ups, keeping vehicles running smoothly."
}

                   `}
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/car-mechanic"} />
            </Helmet>
            <div className={styles.container}>
                <h1>How to Choose a Car Mechanic in Los Angeles?</h1>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <p>
                            As a rule, many of us do not trust mechanics for car diagnostics, maintenance, and repairs.
                            We all need a guarantee that an auto mechanic will professionally service our car.
                        </p>
                        <p>For this reason, many car owners in LA still have one question - "Where to repair and service
                            your car, and how to choose a car mechanic in Los Angeles?" Yes, of course, you can contact
                            an unofficial dealer who has the whole range of equipment and auto mechanics, but you will
                            not have the guarantee that your vehicle diagnostics will be as professional as in a
                            professional <a href="https://lawestcollision.com/" target="_blank" style={{color:"#ededed"}}><b>collision center</b></a>. Plus, the prices of such car services will not be much lower
                            than in the professional collision center. Therefore, remember, in order to be sure that
                            your car diagnostic will be serviced and repaired properly, you need to find a professional
                            car mechanic in Los Angeles who works in his own mini-service or in an auto body shop. </p>
                        <p>So where can you find a professional auto mechanic who can do a professional auto diagnostic
                            and the other services connected with car maintenance? First of all, you can call LA West
                            auto body shop. Certified technicians completing mechanical repairs exceeding the industry's
                            standards will help you with vehicle diagnostics, <a href="https://lawestcollision.com/vehicle-frame-repair" target="_blank" style={{color:"#ededed"}}><b> car frame repairs</b> </a>, car painting, and
                            everything connected with vehicle repairs. There are no outstanding issues with LA West!
                        </p>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Communication and Rumors </h2>
                        <div className={styles.text1}>
                            <p>How it will help: Talking to real people, not virtual ones on the Internet, is the best
                                way to find information about the job of an auto mechanic, about which people respond
                                most positively. The main criterion by which it is necessary to select an auto mechanic
                                is his term of work in one place. As a rule, in auto repair shops, there is a high
                                turnover of personnel. Non-professionals do not stay long in-car services. </p>
                        </div>
                        <div className={styles.text2}>
                            <p>If you have received information that an auto mechanic has been working in some service
                                for a long time, then he probably does professional work with car diagnostics and you
                                need to learn more about his work by finding people in your city or locality who will
                                give you an impartial review.

                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2> Search For A Good Car Service With A Large Number Of Cars</h2>
                        <div className={styles.text1}>
                            <p>
                                Look for a car service with a large flow of cars that come for repairs. Pay attention to
                                how fast they repair cars. If half of the cars have not yet been repaired after your
                                arrival in two weeks, you should not contact such a workshop.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>
                                If you see that a car service can handle a large number of cars, then this is a good
                                sign that the management of the car repair shop has established professional car repair
                                work by recruiting competent specialists.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2> Find Out What Parts Are Used In A Car Service </h2>
                        <div className={styles.text1}>
                            <p>
                                Most companies produce auto parts, both cheap and expensive. Moreover, for one car, the
                                plant can produce spare parts that are not inferior in quality to the original ones, or
                                of lower quality. Unfortunately, by appearance, you will not be able to distinguish the
                                quality of spare parts.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>
                                Contact the car service management or manager to find out the prices for the cost of
                                repairs and maintenance, as well as find out the prices, of spare and consumable parts
                                of cars, specifying which parts manufacturer their workshop works with. Remember that
                                non-original parts do not mean that they are of poor quality. It all depends on the
                                manufacturer.

                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default CarMechanic;
import React from 'react';
import {Carousel} from 'react-carousel3';
import img1 from "../../Assets/Images/nissan.png"
import img2 from "../../Assets/Images/kia.png"
import img3 from "../../Assets/Images/corporate3.jpeg"
import img5 from "../../Assets/Images/mopar.png"
import img6 from "../../Assets/Images/corporate6.webp"
import img7 from "../../Assets/Images/corporate7.webp"
import img8 from "../../Assets/Images/corporate8.webp"
import img10 from "../../Assets/Images/corporate99.webp"
import img11 from "../../Assets/Images/corporate11.webp"
import img12 from "../../Assets/Images/corporate12 (1).webp"
import img15 from "../../Assets/Images/corporate15.jpeg"
import img16 from "../../Assets/Images/tesla.png"
import img17 from "../../Assets/Images/hummer.png"
import img18 from "../../Assets/Images/gm.png"
import img19 from "../../Assets/Images/gmc.png"
import img20 from "../../Assets/Images/corvette-1.png"
import img21 from "../../Assets/Images/Chevrolet.png"


import "./style.scss"

const style = {
    // width: 297,
    // height: 296,
};

const Slider3D = () => (
    <div className={"Carousel"}>
        <div className={"filter"}></div>

        <h2>WE COOPERATE WITH</h2>
        <p>LA West Collision Center proudly cooperates with a wide range of esteemed automotive manufacturers, including
            Mercedes-Benz, Infiniti, Nissan, Kia, Hyundai, Alfa Romeo, Chrysler, Dodge, Fiat, Jeep, Ram, and Mopar. With
            these partnerships, LA West Collision Center ensures that customers' vehicles from these brands receive
            top-notch repair and maintenance services. Whether it's restoring the luxury of a Mercedes-Benz or the
            performance of a Dodge, LA West Collision Center's skilled technicians are equipped with the knowledge and
            expertise to handle all repairs and servicing needs for these esteemed manufacturers.</p>
        <div
            className="CarouselBox"
        >
            <Carousel height={500} style={{display: "flex", justifyContent: "center"}} width="100%" yOrigin={42}
                      yRadius={48} autoPlay={true}>
                <div key={1} style={style}>
                    <img alt="" src={img1}/>
                </div>
                <div key={2} style={style}>
                    <img alt="" src={img2}/>
                </div>
                <div key={3} style={style}>
                    <img alt="" src={img3}/>
                </div>
                <div key={5} style={style}>
                    <img alt="" src={img5}/>
                </div>
                <div key={6} style={style}>
                    <img alt="" src={img6}/>
                </div>
                <div key={7} style={style}>
                    <img alt="" src={img7}/>
                </div>
                <div key={8} style={style}>
                    <img alt="" src={img8}/>
                </div>
                <div key={9} style={style}>
                    <img alt="" src={img15}/>
                </div>
                <div key={10} style={style}>
                    <img alt="" src={img10}/>
                </div>
                <div key={11} style={style}>
                    <img alt="" src={img11}/>
                </div>
                <div key={12} style={style}>
                    <img alt="" src={img12}/>
                </div>
                <div key={13} style={style}>
                    <img alt="" src={img16}/>
                </div>
                <div key={14} style={style}>
                    <img alt="" src={img17}/>
                </div>
                <div key={15} style={style}>
                    <img alt="" src={img18}/>
                </div>
                <div key={16} style={style}>
                    <img alt="" src={img19}/>
                </div>
                <div key={17} style={style}>
                <img alt="" src={img20}/>
                </div>


            </Carousel>
        </div>
    </div>
);

export default Slider3D
import React from 'react';
import styles from "./Blog.module.scss"
// import img1 from "../../Assets/Images/blog3.jpg"
// import img from "../../Assets/Images/service17.jpeg";
const BlogItem = ({link,title,desccription,image}) => {
    return (
        <div className={styles.blogItem}>
            <h4>How To Find An Auto Mechanic You Can Trus</h4>
            <div className={styles.box}>
                <div className={styles.mainInfo}>
                    <img src={image} alt=""/>
                    <div>
                        <h2>{title} </h2>
                        <p>
                            {desccription}
                        </p>
                        <p>[...]</p>
                        <a target={"_blank"} href={link} style={{color:"#ED1C24",fontFamily:"hemi_head"}}>Read More</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogItem;
import React, {useEffect} from 'react';
import styles from "./AboutUs.module.scss"
import logo from "../../Assets/Images/logo.png"
import AboutUsItem from "./AboutUsItem";
import Layout from "../../Layouts/Layouts";
import {Helmet} from 'react-helmet';
import {useMeta} from "../../MetaContext";


const AboutUs = () => {
    return (
        <Layout>
            <Helmet>
                <title>About Us | La West</title>
                <meta name="description" content="Welcome to LA West Collision Center in Los Angeles, CA.  Our team of highly skilled professionals focus on meeting your need for speed and quality." />
                <link rel="canonical" href={"https://www.lawestcollision.com/about"} />
                {/* Add more meta tags as needed */}
            </Helmet>
            <div className={styles.aboutUs}>
                <div className={styles.logo}>
                    <img src={logo} alt="logo"/>
                    <h2>SINCE 2015</h2>
                    <h2>LOS ANGELES</h2>
                    <h1>ABOUT US</h1>
                </div>
                <div className={styles.items}>
                    <p className={styles.item1}>Since our establishment in 2015, LA West Collision Center has proudly served as a trusted destination for premier collision repair services in Los Angeles. With a commitment to excellence and a passion for customer satisfaction, we have earned a solid reputation in the industry.</p>
                    <p className={styles.item2}>At LA West Collision Center, we understand the importance of building strong relationships with insurance companies. Our exceptional track record and commitment to quality have established us as a preferred partner for all major insurance providers. This allows us to navigate the claims process seamlessly, ensuring a smooth and hassle-free experience for our customers. </p>
                    <p className={styles.item3}>We are honored to be certified by renowned automotive manufacturers including Mercedes-Benz, Infiniti, Nissan, Kia, Hyundai, Alfa Romeo, Chrysler, Dodge, Fiat, Jeep, Ram, and Mopar. These certifications reflect our expertise and our dedication to meeting the highest standards set by these esteemed brands. Our certified technicians undergo rigorous training and stay updated with the latest repair techniques specific to each manufacturer, ensuring that your vehicle receives the utmost care and precision it deserves.</p>
                    <p className={styles.item4}>- In recognition of our unwavering commitment to excellence, LA West Collision Center proudly holds the I-CAR Gold Class Award. This prestigious designation is bestowed upon collision repair facilities that meet or exceed the industry's highest standards for training and technical expertise. As an I-CAR Gold Class certified facility, we continuously invest in training and education to stay ahead of industry advancements, providing our customers with superior service and results.</p>
                </div>

                <h3>We value all our clients and future friends. </h3>
            </div>
            {/*<AboutUsItem/>*/}
        </Layout>
    );
};

export default AboutUs;
import React, {useRef, useState} from 'react';
import styles from "./Careers.module.scss";
import {toast} from "react-toastify";

const CareersItem = ({text,title}) => {
    const [file, setFile] = useState(null)
    const ref = useRef();
    const handleFile = (e) => {
        setFile(e.target.files[0])
    }
    const handleClick = (value) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("position", title);
        fetch(`https://api.lawestcollision.com/attach-cv`, {
            method: 'post',
            body: formData,
        });
        toast.success('Successfully Sent !', {
            position: toast.POSITION.TOP_RIGHT
        });
        ref.current.value = "";
        setFile(null)
    }
    return (
        <div className={styles.careersItem}>
                <div className={styles.mainInfo}>
                    <div>
                        <h2>{title}</h2>
                        <p>{text}</p>
                        <div style={{textAlign: "center"}}>
                            <div className={styles.file_box}>
                                <label className={styles.labell} htmlFor="file-input">Attach Your Resume</label>
                                <input className={styles.custom_file_input} ref={ref} type="file" onChange={handleFile} />
                            </div>
                            <button name="submit" onClick={handleClick} type="submit" id="contact-submit"
                                    data-submit="...Sending">Send
                            </button>
                        </div>
                    </div>
            </div>
        </div>

    );
};

export default CareersItem;
import React from 'react';
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss";
import img from "../../Assets/Images/mers.avif";

const MercedesBenz = () => {
    return (
        <Layout>
            <Helmet>
                <title>Mercedes Benz Certified Collision Center In Los Angeles</title>
                <meta name="description"
                      content="Mercedes-Benz collision repair at Mercedes certified body shop in Los Angeles. Our expert technicians use genuine Mercedes parts and cutting-edge technology."/>

                <link rel="canonical" href={"https://www.lawestcollision.com/certificates/mercedes-collision-center"}/>
            </Helmet>
            <div className={styles.container}>
                <h1>Mercedes Benz Collision Center</h1>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <p>
                            Talking about Mercedes Benz and how these cars are good is pointless. We all know that the
                            brand manufactures the luxury segment of cars, which is justified because the parts of those
                            cars are high quality and can't be replaced with other substitute parts. The Mercedes Benz
                            concern has existed for over a hundred years, producing car models of various classes and
                            everything necessary for their operation and care. Problems with malfunctions for this brand
                            should be resolved only in the Benz collision center, avoiding unofficial entrepreneurs.
                        </p>
                        <p>But the thing is, only some collision centers are Mercedes-Benz certified. Mercedes Benz
                            Collision Center must have all the parts for their clients.
                        </p>
                        <p>In the list of <a href="https://www.lawestcollision.com/certificates" target={"_blank"} style={{color:"#ED1C24",fontWeight:"bold"}}>LA West’s Certifications</a>, you can see that we are Mercedes Benz certified and
                            LA West is a Mercedes Benz collision center.
                        </p>
                        <p>LA West <a href="https://www.lawestcollision.com" target={"_blank"} style={{color:"#ED1C24",fontWeight:"bold"}}>Collision Center</a> carries out repairs and diagnostics of Mercedes-Benz vehicles of
                            various levels of complexity and does it skillfully and professionally. Our specialists have
                            extensive experience working with cars, which is a guarantee of error-free work and accurate
                            diagnosis of your car. We proudly say that we are a Mercedes Benz bodyshop. One of the main
                            advantages of our Mercedes Benz collision center is the large number of spare parts
                            available in our warehouse.
                        </p>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Benefits of Mercedes Benz Bodyshop </h2>
                        <div className={styles.text1}>
                            <p>Mercedes Benz cars are rightfully considered a model of reliability and quality. Their
                                service life without complex repairs amounts to more than one hundred thousand miles,
                                but they are subject to regular and high-quality maintenance.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>The necessary care can only be provided by a Mercedes Benz-certified body shop, in which
                                qualified craftsmen will perform all work using original spare parts, and consumables,
                                as well as their high-quality analogs. In this case, compliance with all technological
                                requirements of the Mercedes Benz automaker is of no small importance.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Why Is It Important To Maintain Your Mercedes Benz? </h2>
                        <div className={styles.text1}>
                            <p>While German cars have a proven track record of quality, style, and performance, they also require specialist attention from time to time. Any car enthusiast living in a big city, for example in Los Angeles, knows that all parts wear out over time and fail even with careful use; in such cases, <a
                                href="https://reliabilityonivanhoehill.com/mercedes-repair-los-angeles" target={"_blank"} style={{color:"#ED1C24",fontWeight:"bold"}}>Mercedes Benz repair</a> consists of the correct selection and replacement of components. Bad roads and other external factors cannot ensure even a very good and high-quality car against breakdowns. The driver of a German car of this brand should select in advance a reliable Mercedes Benz collision center that can provide the full range of services necessary for your vehicle.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>So if you are looking for a reliable Mercedes Benz collision center with high-quality work, optimal prices, and a wide range of services, then the LA West Mercedes Benz certified collision center is the best choice!
                                Our car body shop employs professional technicians who have extensive experience working with equipment of this brand. We strictly follow the recommendations and regulations established by the manufacturer. Our Mercedes Benz service uses only original components and high-quality technical fluids. Mechanics of <a
                                    href="https://www.lawestcollision.com" target={"_blank"} style={{color:"#ED1C24",fontWeight:"bold"}}>LA West Collision Center</a> are able to quickly diagnose and eliminate any malfunction.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

);
};

export default MercedesBenz;
import React, {useEffect} from 'react';
import './index.scss'
import Layout from "../../Layouts/Layouts";
import Item from "./item";
import {Helmet} from "react-helmet";

const Index = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'Find answers to all your questions about collision repairs at LA West Collision Center\'s FAQ page. Browse our FAQ page today for more information!')
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Frequently Asked Questions | LA West</title>
                <script type="application/ld+json">
                    {
                        `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What repairs do you do?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We do all types of body repairs, no matter how big or small the damages are, we also do mechanical work thru insurance."
    }
  },{
    "@type": "Question",
    "name": "Do I need to schedule an appointment?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Appointments are not required but strongly recommended to ensure everything is prepared and curated to each customer."
    }
  },{
    "@type": "Question",
    "name": "How long should I wait to wash my car after it is repaired?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We wash all vehicles after repairs as a procedure of our quality control program."
    }
  },{
    "@type": "Question",
    "name": "I have a brand new vehicle that has been involved in an accident. Can it look like new again?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We follow the latest dealer procedures when repairing vehicles, your vehicle will be repaired with no minor or major imperfections. Will look exactly how it was prior to the collision"
    }
  },{
    "@type": "Question",
    "name": "Do I have to go to the preferred shop recommended by my insurance company?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "No, Insurance simply suggests a shop, it is against the law to force a customer to take their vehicle anywhere. All customers carry the right of free will when it comes to choosing a repair facility."
    }
  },{
    "@type": "Question",
    "name": "How long will it take to repair my vehicle?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Every vehicle has a unique time for the completion of repairs. No two collisions have the same damages."
    }
  },{
    "@type": "Question",
    "name": "How can I pay for the repairs?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Payment is accepted in Cash, Apple Pay, Samsung Pay, Credit & Debit Cards, Cashiers Check , Insurance checks"
    }
  },{
    "@type": "Question",
    "name": "How do I know when my car will be ready?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Every customer will receive automated text messages (Carrier Rates May Apply) updating them with the latest information on their vehicle, also all customers are welcome to call our direct line (818)821-6033 for any questions regarding their repairs."
    }
  },{
    "@type": "Question",
    "name": "If Something Goes Wrong With My Repairs, Who Do I Contact?",
    "acceptedAnswer": {
     "@type": "Answer",
      "text": "We choose to eliminate any issues per our quality control procedures however, any issues that arise can be solved with a simple call to our facility (818)821-6033"
    }
  },{
    "@type": "Question",
    "name": "I was just involved in an accident. What do I do now?",
    "acceptedAnswer": {
      "@type": "Answer",
           "text": "Once involved in an accident make sure to swap information of the opposing party once in a safe environment, depending on the severity of the accident call 911, give LA West Collision Center a call so we can dispatch our tow truck to speed up the process of your accident. We also provide complimentary claim assistance per request."
    }
  },{
    "@type": "Question",
    "name": "Does my insurance adjuster need to see my car first?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We deal with all insurance questions or concerns, simply drop off your vehicle and inform your insurance company that we are in possession of your vehicle,we will deal with all their questions so our client doesn't have to."
    }
  },{
    "@type": "Question",
    "name": "Do I need more than one estimate for my insurance company?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Estimates & Supplements all depend on the vehicle's damages."
    }
  },{
    "@type": "Question",
    "name": "What can I expect when bringing my vehicle to LA West",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Quality workmanship, dealer quality repairs , impeccable customer service."
    }
  },{
    "@type": "Question",
    "name": "Will my car be repaired using original factory parts?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We try to eliminate the use of aftermarket parts, however some insurance companies (Depending on your policy coverages) only pay and approve for aftermarket parts. Every customer can call and request OEM parts from their insurance company."
    }
  },{
    "@type": "Question",
    "name": "Will I be able to wash my vehicle after the repairs?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We wash every vehicle after repairs so the customer won't have to, but every vehicle is good to wash unless instructed otherwise from our certified technicians."
    }
  }]
}

`


                    }
                </script>
                <meta name="description"
                      content={"Find answers to all your questions about collision repairs at LA West Collision Center's FAQ page. Browse our FAQ page today for more information!"}/>
                <link rel="canonical" href={"https://www.lawestcollision.com/faq"}/>
            </Helmet>
            <div className="containerFaq">
                <div className="wrapper">
                    <h1>Frequently Asked Questions</h1>
                    <Item question="What repairs do you do?"
                          answer="We do all types of body repairs, no matter how big or small the damages are, we also do mechanical work thru insurance."/>
                    <Item question="Do I need to schedule an appointment?"
                          answer="Appointments are not required but strongly recommended to ensure everything is prepared and curated to each customer."/>
                    <Item question="How long should I wait to wash my car after it is repaired?"
                          answer="We wash all vehicles after repairs as a procedure of our quality control program."/>
                    <Item
                        question="I have a brand new vehicle that has been involved in an accident. Can it look like new again?"
                        answer="We follow the latest dealer procedures when repairing vehicles, your vehicle will be repaired with no minor or major imperfections. Will look exactly how it was prior to the collision"/>
                    <Item question="Do I have to go to the preferred shop recommended by my insurance company?"
                          answer="No, Insurance simply suggests a shop, it is against the law to force a customer to take their vehicle anywhere. All customers carry the right of free will when it comes to choosing a repair facility."/>
                    <Item question="How long will it take to repair my vehicle?"
                          answer="Every vehicle has a unique time for the completion of repairs. No two collisions have the same damages. "/>
                    <Item question="How can I pay for the repairs?"
                          answer="Payment is accepted in Cash, Apple Pay, Samsung Pay, Credit & Debit Cards, Cashiers Check , Insurance checks "/>
                    <Item question="How do I know when my car will be ready?"
                          answer="Every customer will receive automated text messages (Carrier Rates May Apply) updating them with the latest information on their vehicle, also all customers are welcome to call our direct line (818)821-6033 for any questions regarding their repairs."/>
                    <Item question="If Something Goes Wrong With My Repairs, Who Do I Contact?"
                          answer="We choose to eliminate any issues per our quality control procedures however, any issues that arise can be solved with a simple call to our facility (818)821-6033 "/>
                    <Item question="I was just involved in an accident. What do I do now?"
                          answer="Once involved in an accident make sure to swap information of the opposing party once in a safe environment, depending on the severity of the accident call 911, give LA West Collision Center a call so we can dispatch our tow truck to speed up the process of your accident. We also provide complimentary claim assistance per request. "/>
                    <Item question="Does my insurance adjuster need to see my car first?"
                          answer="We deal with all insurance questions or concerns, simply drop off your vehicle and inform your insurance company that we are in possession of your vehicle,we will deal with all their questions so our client doesn't have to."/>
                    <Item question="Do I need more than one estimate for my insurance company?"
                          answer="Estimates & Supplements all depend on the vehicle's damages."/>
                    <Item question="What can I expect when bringing my vehicle to LA West"
                          answer="Quality workmanship, dealer quality repairs , impeccable customer service."/>
                    <Item question="Will my car be repaired using original factory parts?"
                          answer="We try to eliminate the use of aftermarket parts, however some insurance companies (Depending on your policy coverages) only pay and approve for aftermarket parts. Every customer can call and request OEM parts from their insurance company."/>
                    <Item question="Will I be able to wash my vehicle after the repairs?"
                          answer="We wash every vehicle after repairs so the customer won't have to, but every vehicle is good to wash unless instructed otherwise from our certified technicians."/>
                </div>
            </div>
        </Layout>

    );
};

export default Index;
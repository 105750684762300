import React from 'react';
import classes from "./Blog.module.scss"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/blog44.jpg";
import img1 from "../../Assets/Images/blog33.jpg";
import img2 from "../../Assets/Images/blog55.jpg";
import img5 from "../../Assets/Images/blog22.jpg";
import img3 from "../../Assets/Images/diagnostics.jpeg";
import img4 from "../../Assets/Images/blog3.jpg";

const Blog7 = () => {
    return (
        <Layout>
            <Helmet>
                <title>Car Frame Damage: What Is It & Can Frame Damage be Fixed?</title>
                <meta name="description"
                      content={"What is frame damage on a car? Our experts explain what car frame damage is and how it can be fixed. Get informed and make the right decisions for your vehicle."}/>
                <script type="application/ld+json">
                    {
                        `
                    {
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": "https://www.lawestcollision.com/car-frame-damage"
                    },
                        "headline": "Car Frame Damage: What Is It & Can Frame Damage be Fixed?",
                        "description": "What is frame damage on a car? Our experts explain what car frame damage is and how it can be fixed. Get informed and make the right decisions for your vehicle.",
                        "image": {
                        "@type":"ImageObject",
                        "url":"https://www.lawestcollision.com/static/media/blog55.e03a0a02b651c1f08da7.jpg",
                        "width":800,
                        "height":600
                        },
                        "author": {
                        "@type": "Organization",
                        "name": "LA West"
                    },
                        "publisher": {
                        "@type": "Organization",
                        "name": "La West",
                        "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg"
                    }
                    },
                        "datePublished": "2023-11-03",
                        "dateModified": "2023-11-03"
                    }`
                    }
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/car-frame-damage"}/>
            </Helmet>

            <div className={classes.textBlog1}>
                <h1 className={classes.titleBlog}>What is Car Frame Damage: Importance of Professional Services</h1>
            </div>
            <div className={styles.itemBlock}>
                <div>
                    <div className={styles.text1} style={{textAlign: "center"}}>
                        <p style={{maxWidth: "100%"}}>The frame of a car is the main supporting structure to which other
                            components are attached. This means that car frame damage is a serious problem for car
                            owners. In this article, we will discuss topics such as signs of car frame damage, parts of
                            a car frame, etc.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.mainInfo} style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div>
                        <h2>What is The Frame of The Car?</h2>
                        <p>
                            The frame structure of the body was used on almost all cars in the world until the early
                            1960s. The first frames were made of wood. In the 1930s, wooden frames were replaced by
                            steel frames. Over time, almost all passenger cars have changed their design to a "carrier
                            body". Nowadays, the frame body is mainly used for SUVs, trucks, and buses. The longitudinal
                            beams of the frame take on the main loads. Crossbars provide resistance to lateral loads and
                            increase torsional rigidity.
                        </p>
                        <p>A single-layer metal frame is used on most small and medium vehicles. Some frames consist of
                            two layers of metal, and have an additional beam inside the main beam. This design provides
                            increased strength without loss of flexibility. This design can be on large trucks. Often
                            double metal is used only on individual sections of the frame. Different manufacturers have
                            their own design and development concepts to achieve the desired frame performance.
                        </p>
                        <p>Well, now you know what is the frame of the car itself, but what is frame damage on a car?
                        </p>
                    </div>
                    <img src={img2} alt=""/>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "50px", marginBottom: "50px"}}>
                    <img src={img1} alt=""/>
                    <div>
                        <h2>What is Frame Damage On a Car? </h2>
                        <p>
                            Car frame damage can result from various factors, including normal wear and tear over many
                            years of usage, damage from collisions, and, last but not least, long-term storage without
                            garages once the automobile is taken out of use.
                        </p>
                        <p>Depending on the level of the car frame damage, the repair price can be different. For
                            example, bent car frame repair costs can be thousands of dollars. So now we definitely know
                            that car frame damage is a serious issue for the car and no one can do <a
                                href="https://www.lawestcollision.com/vehicle-frame-repair" target={"_blank"}
                                style={{color: "#eee"}}><b>car frame repairs</b></a> than professionals from <a
                                href="https://www.lawestcollision.com/what-to-do-after-car-accident" target={"_blank"}
                                style={{color: "#eee"}}><b>car body shops.</b></a>
                        </p>
                        <p>So one of the worst possible outcomes is typically damage to a car's frame or body.
                            Additionally, structural damage car meaning structural damage is no longer structurally
                            sound.
                        </p>
                    </div>
                </div>

                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <h2>Materials of Car Frames </h2>
                        <p>
                            The typical material used to build a car frame is carbon steel or aluminum alloys (to
                            achieve a lighter-weight design).
                        </p>
                        <p>In the case of a single frame, it is made up of structural elements called beams. These are
                            simple structures made of steel profiles of different sections.
                        </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>Frame sections can be made from a C-profile (the most common type of beam).
                            </li>
                            <li>Box profile (square tube)
                            </li>
                            <li>tubular sections (round tube).
                            </li>
                            <li>Frames may have rounded arches. Thus, instead of rising above the axle shafts, the frame
                                with arches is lower, approximately flush with the axle shafts, and goes around them.
                            </li>

                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div>
                        <h2>Frame Diagnostics </h2>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>If there is suspicion of frame distortion, then diagonal measurements should be taken
                                between control points to determine exactly where the displacement has occurred.
                            </li>
                            <li>For long-frame vehicles, there are several frame measurement methods to determine
                                distortion. All methods use a straight line as the reference coordinate and compare the
                                frame to this line to determine the straightness of the frame. Methods for determining a
                                straight baseline include using a laser beam and pulling on a cord.
                            </li>
                            <li>The use of a laser is an accurate method. The laser is adjusted to one end of the frame,
                                from the edge of the frame, and is directed along the frame to the other end, the same
                                distance from the edge of the frame. A piece of flat material such as cardboard is held
                                against the frame. This is done at several points along the frame. The measurements are
                                recorded and should ideally match at all points. Deviation will mean the frame is bent.
                                You can also check the height. If it deviates up or down, it means a bend in the frame.
                            </li>
                            <li>The cord method is similar to the laser, except that the laser is replaced by a cord.
                            </li>
                            <li>The plumb test does not require special equipment and is suitable if there are too many
                                obstacles along the frame that interfere with measurement and are difficult to
                                dismantle. The method uses a plumb bob to mark points on the floor of the workshop and a
                                cord to connect those points. Symmetrical points are selected on the bottom of the frame
                                beams. A plumb bob is lowered from the outside of the symmetrical points to make chalk
                                marks on the floor. The dots are connected. All diagonals must cross the center line if
                                the frame is level.
                            </li>
                        </p>
                    </div>
                    <img src={img3} alt=""/>
                </div>

                <div className={styles.mainInfo}>
                    <img src={img3} alt=""/>
                    <div>
                        <h2>Frame Reinforcement </h2>
                        <p>
                            There are two types of reinforcements - inserting a profile reinforcement inside (or
                            outside) or a reinforcing overlay. Each type of amplifier offers a different degree of
                            durability. The choice of reinforcement method should be based on what is best suited to the
                            particular frame and location of the repair site.
                        </p>
                        <p>All reinforcements must be made from the same metal, the same thickness as the frame. If the
                            frame is made of high-strength steel, then the reinforcement must be made of high-strength
                            steel.
                        </p>
                        <p>Reinforcing pads are used on frames most often. They distribute the voltage (from the load)
                            over a large area. It doesn't make sense to use a thicker metal bezel, as it won't bend as
                            much as the rest of the frame, which can cause cracking.
                        </p>
                        <p>
                            On reinforcing pads and profile reinforcements there should be no sides passing across the
                            frame beams. When welding the reinforcement, the seams should run obliquely and along the
                            frame beams.

                        </p>
                        <p>When making reinforcing pads, it is better to make them closer to an oval shape or with
                            beveled rounded corners. This form will minimize the appearance of stress concentration
                            zones in the seams, due to which cracks occur. If the reinforcing strip will have beveled
                            corners, then it is not necessary to weld its side ends completely, since these places are
                            most susceptible to stress concentration.
                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "50px", marginBottom: "50px"}}>
                    <div>
                        <h2>Types of damage to the frame? </h2>
                        <p>Most of the energy during a crash is absorbed by the frame.
                        </p>
                        <p>Depending on the nature of the accident, the following types of defects may occur:
                        </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>Offset in the horizontal and/or vertical plane
                            </li>
                            <li>frame twisting
                            </li>
                            <li>Frame distortion
                            </li>
                            <li>The curvature of the crossbars
                            </li>
                        </p>
                        <p>Often, during an accident, several types of damage occur simultaneously. Part of the frame
                            can move both sideways and down at the same time.
                        </p>
                        <p>
                            In case of minor damage, the frame can be straightened. If the damage is too serious to be
                            repaired, then the damaged metal is cut out and a new one is welded, taking into account the
                            dimensions of the distances between the control points.

                        </p>
                        <p>A damaged frame can cause the body to tilt to one side, and increased wear on suspension
                            components and tires. The two most common frame failures are twisting and bending down. The
                            difference between these two damages is subtle but important from the point of view of
                            repair. Unfortunately, repairing a kink as a twist or vice versa can cause additional damage
                            that is difficult or impossible to repair.
                        </p>
                        <p>Twisting of the frame can occur if the car from acceleration got into a trench. One of the
                            main symptoms of a twisted frame is that the car leans down on one side.
                        </p>
                        <p>Frame twisting usually occurs only in its center section. In this case, damage can appear
                            throughout the body. After the repair, the edges of the frame will snap into place as the
                            twisted center section held them in the wrong position. If you do not correctly diagnose the
                            twisting of the frame and correct its incorrectly located individual parts, then you can
                            only aggravate the damage.
                        </p>
                    </div>
                    <img src={img5} alt=""/>
                </div>
            </div>
        </Layout>
    );
};

export default Blog7;
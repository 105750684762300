import React, {useEffect} from 'react';
import Layout from "../../Layouts/Layouts";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/autoframe.jpg"
import {Helmet} from "react-helmet";

const FrameStraightening = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'Looking for Car frame repair in Los Angeles, CA? Our certified technicians are here to help you get back on the road with minimal downtime. Contact us today!')
    //     let themeMeta=document.querySelector("meta[name='keywords']")
    //     themeMeta.setAttribute("content", "Car Frame Repairs, frame car repair, frame repair shops")
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Frame Straightening | Car Frame Repairs Los Angeles | LA West</title>
                <meta name="description"
                      content="Looking for Car frame repair in Los Angeles, CA? Our certified technicians are here to help you get back on the road with minimal downtime. Contact us today!"/>
                <meta name="keywords" content="Car Frame Repairs, frame car repair, frame repair shops"/>
                <script type="application/ld+json">
                    {`
                    {
  "@context": "https://schema.org",
  "@type": "Service",
  "provider": {
    "@type": "AutoBodyShop",
    "name": "LA West Collision Center",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "6827 Troost Ave North Hollywood, CA 91605",
      "addressLocality": "Los Angeles",
      "postalCode": "91605",
      "addressCountry": "US"
    },
    "telephone": "(818) 821-6033",
    "url": "https://www.lawestcollision.com",
    "logo": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg",
    "description": "LA West is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details.
"
  },
  "serviceType": "Frame Straightening",
  "offers": {
    "@type": "Offer",
    "priceCurrency": "USD",
    "url": "https://www.lawestcollision.com/vehicle-frame-repair"
  },
  "areaServed": {
    "@type": "City",
    "name": "Los Angeles"
  },
  "description": "Frame Straightening service is dedicated to correcting the structural integrity of a vehicle's chassis after it has been misaligned due to accidents or severe impacts. Using specialized equipment and techniques, technicians realign the frame to its original specifications, ensuring the vehicle's safety and proper alignment."
}

                    `}
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/vehicle-frame-repair"} />
            </Helmet>
            <div className={styles.container}>
                <h1>Frame Straightening</h1>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <p>
                            Frame straightening is complex and responsible work. To be honest, few body repair masters
                            can adequately do frame straightening of cars professionally. The frame of a car is the main
                            supporting structure to which other components are attached.
                        </p>
                        <p>
                            During the frame straightening, a damaged frame can be separated from the body, engine, and
                            transmission, its geometry can be completely restored, and then the car can be rebuilt
                            again. This is facilitated by a detachable screw method of connecting the frame with other
                            units.

                        </p>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Types of Damage</h2>
                        <div className={styles.text1}>
                            <p style={{textAlign: 'left'}}>
                                As strong and durable as the frame of a car is, this welded steel structure cannot
                                withstand three destructive factors and will definitely need care frame repairs:
                                <li style={{marginLeft: '20px'}}>Deformations in an accident.</li>
                                <li style={{marginLeft: '20px'}}>Geometry violations due to impact on obstacles.</li>
                                <li style={{marginLeft: '20px'}}>Corrosion damage due to long-term use.</li>
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>
                                If the owner is attentive to the technical condition of his car and checks the condition
                                of the car in the <a href="https://lawestcollision.com/" target="_blank"
                                                     style={{color: "#ededed"}}><b>collision center</b></a>, corrosion
                                processes do not threaten his power
                                structures. In most cases, the need for car straightening services arises after traffic
                                accidents.

                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2> 3 Degrees of Complexity of Car Frame Repair </h2>
                        <div className={styles.text1}>
                            <p>
                                In some cases, frame car repair after an accident can be performed without disconnecting
                                it from the rest of the haul and structures of the vehicle. But if the level of damage
                                is high, then the frame has to be removed and pulled out on a special stand - a slipway.
                                Specialists distinguish three main groups of defects and options for the production of
                                restoration work:
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>
                                1. Minor frame damage. When the impact did not affect the overall geometry of the frame
                                and the position of the wheels in the vertical and horizontal plane, the repair can be
                                carried out without separation from the body and the aggregate hitch. Thus, deformations
                                of the front and rear end elements of the frame structure are eliminated, or broken body
                                mounting brackets are restored.
                            </p>
                        </div>
                        <div className={styles.text1}>
                            <p>2. Repair of medium complexity. This group includes repairs that are performed after the
                                frame has been dismantled, but are necessary to eliminate the consequences of impacts
                                that did not affect the position of the wheel axles. Most often, this is done if it is
                                necessary to restore the shape of the box of the power structure. In addition, after
                                removing the frame, it will be much more convenient for the tinsmith to repair damaged
                                areas of the body.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>3. Severe frame damage. A high degree of complexity of work includes cases when, as a
                                result of an impact, the mutual position of the wheel axles has changed, a wrinkle has
                                formed on the frame, or any other significant changes in its geometry have occurred.
                                Restoration is carried out in stages on a body straightening machine. This is a complex
                                procedure that is performed by attaching the frame structure to the slipway and
                                stretching it along the control factory points.
                            </p>
                        </div>
                        <div className={styles.text1}>
                            <p>
                                The duration of assembly of the restored frame with the rest of the vehicle components
                                often takes up to four hours.
                                At LA West car frame straightening is carried out with 3D scanning and laser
                                measurements following the training and requirements from the CAR-O-LINER manufacturer.
                                With us, you will find not only professional frame straightening service, but also car
                                towing, <a href="https://lawestcollision.com/car-painting" style={{color: "#ededed"}}
                                           target={"_blank"}><b>car painting</b></a>, <a
                                href="https://lawestcollision.com/fiberglass-repair/" style={{color: "#ededed"}}
                                target={"_blank"}><b>fiberglass repair</b></a>, etc. Give life to your car with LA West!
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Is It Possible to Repair The Frame With Your Hands?</h2>
                        <div className={styles.text1}>
                            <p>
                                Even if the owner of the car, a non-professional, has personal experience in auto repair
                                and has all the necessary tools for cutting and welding metal, he will be able to
                                eliminate only the most minor frame defects.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>
                                For example, cut out the damaged area and weld a plate of the appropriate shape in its
                                place. But even this work can be done by hand only if the frame geometry has not been
                                damaged. Otherwise, you still have to turn to professionals. Therefore, call the frame
                                repair shops if you have recently been in an accident, even a low-speed one.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default FrameStraightening;
import React, {useEffect} from 'react';
import Header from "../../Components/Header/Header";
import ManipulatingSwiper from "../../Components/SwiperGallery/SwiperGalerry";
import Slider from "../../Components/Slider/Slider";
import Insurance from "../../Components/Insurance/Insurance";
import classes from "./Home.module.scss"
import Slider3D from "../../Components/3dSlider/3dSlider";
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import { Person as PersonSchema }  from 'schema-dts';
import { helmetJsonLdProp } from "react-schemaorg";

const Home = () => {
    const navigate = useNavigate()
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'LA West  is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details.')
    //     let themeMeta=document.querySelector("meta[name='keywords']")
    //     themeMeta.setAttribute("content", "auto body shop, collision center, car body shop, auto body shop los angeles, collision repair")
    //
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Auto Body Shop LA: Collision Center in Los Angeles | LA West</title>
                <meta name="description"
                      content="LA West  is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details."/>
                <meta name="keywords"
                      content="auto body shop, collision center, car body shop, auto body shop los angeles, collision repair"/>
                <link rel="canonical" href={"https://www.lawestcollision.com"} />
            </Helmet>
            <Header/>
            <div className={classes.aboutUs}>
                <div className={classes.text}>
                    <h1>Auto Body Shop in Los Angeles</h1>
                    <p>LA West Collision Center established in 2015 is the fastest-growing automotive repair facility in
                        the greater Los Angeles area. Our primary focus and goal have always been to make customers feel
                        like they are part of the LA West Family. As of 2022, LA West Collision Center is officially the
                        direct repair facility for Mercedes Benz. Our Auto Body Shop is also manufacturer certified by
                        Infiniti, Nissan, Kia, Hyundai, Alfa Romeo, Chrysler, Dodge, Fiat, Jeep, Ram & Mopar. We are
                        happy to ensure the safety of our customers by following all dealer protocols and procedures
                        when completing repairs. LA West Auto Body Shop is also pleased to announce that all our staff
                        is I-Car Gold certified, trained, and ready to repair your vehicle and return it to the client
                        in the showroom condition it once was.

                        The team of LA West Car Body Shop is an expert with a wealth of knowledge in all types of auto
                        repairs for all vehicle makes. We recommend the best course of action for our clients while
                        outlining potential solutions for fixing your car. We value every one of our customers and
                        potential friends.</p></div>
            </div>
            <Slider3D/>
            <div className={classes.services}>
                <div className={classes.title}><h2>Our Services</h2>
                    <p>Don't put off visiting our auto servicing specialists if your car is starting to have problems.
                        Always keep in mind that you will enjoy the drive more if you pay more attention to your car.
                        Regular inspections of your car are necessary to maintain the safety of the driver and their
                        passengers because sensors do not always reveal vehicle problems.
                        Contact our <a style={{color: "#EDEDED"}} href="https://lawestcollision.com/auto-body-repair"
                                       target={"_blank"}><b>auto body repair</b></a> center for an inspection, the
                        earlier we can identify the issue the sooner we will eliminate it.
                    </p>
                </div>
                <ManipulatingSwiper/>
                {/*<div className={classes.aboutUs}>*/}
                {/*    <div className={classes.text}>*/}
                {/*        <p style={{paddingTop:"30px"}}>Don't put off visiting our auto servicing specialists if your car is starting to have*/}
                {/*            problems. Always keep in mind that you will enjoy the drive more if you pay more attention*/}
                {/*            to your car. Regular inspections of your car are necessary to maintain the safety of the*/}
                {/*            driver and their passengers because sensors do not always reveal vehicle problems.*/}
                {/*        </p>*/}
                {/*        <p>Contact our <a href="https://lawestcollision.com/auto-body-repair" style={{color:"#ededed"}} target={"_blank"}><b>auto body repair </b></a>center for an inspection, the earlier we can identify the issue*/}
                {/*            the sooner we will eliminate it. </p></div>*/}
                {/*</div>*/}
            </div>
            <div className={classes.title}><h2>Gallery</h2>
                <p>Witness the transformation in our before and after repairs gallery. Our skilled technicians are ready
                    for ensuring flawless repairs and a seamless finish. Explore the images to see the remarkable
                    results achieved through our comprehensive collision repair services.</p>
            </div>
            <Slider/>
            <div className={classes.title}><h2>Insurance</h2>
            </div>
            <Insurance/>
            <div className={classes.contact}>
                <div className={classes.text}>
                    <h4>LET’S GET IN TOUCH</h4>
                    <h3><span>[</span> CONTACT US <span>]</span></h3>
                    <p>Contact us by phone or through the website's feedback form if you want our auto body shop in Los
                        Angeles to promptly and effectively diagnose your vehicle. Finding an experienced and
                        knowledgeable <a
                            href="https://lawestcollision.com/car-mechanic" target="_blank"
                            style={{color: "#EDEDED"}}><b>auto mechanic</b></a> can be difficult, but with the right
                        auto body shop in Los Angeles, you can be sure that your car will be taken care of properly. A
                        manager or a certified technician will contact you within 30 minutes and schedule a convenient
                        time for your visit.</p>
                </div>
            </div>
        </Layout>
    );
};

export default Home;
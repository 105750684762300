import React, {useEffect, useState} from 'react';
import {Navigate, redirect, Route, Routes, useLocation} from "react-router-dom";
// import loadable, {lazy} from "@loadable/component";
// import {PuffLoader} from "react-spinners";
import ScrollToTop from "./ScrollToTop";
import Home from "./Pages/Home/Home";
import Services from "./Pages/Services/Services";
import Gallery from "./Pages/Gallery/Gallery";
import Partners from "./Pages/Partners/Partners";
import Certificates from "./Pages/Certificates/Certificates";
import Careers from "./Pages/Careers/Careers";
import Blog from "./Pages/Blog/Blog";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Faq from "./Pages/Faq";
import Term from "./Pages/Term";
import Privacy from "./Pages/Privacy";
import AutoBodyRepair from "./Pages/AutoBodyRepair/AutoBodyRepair";
import CarPainting from "./Pages/CarPainting/CarPainting";
import FiberglassRepair from "./Pages/FiberglassRepair/FiberglassRepair ";
import CarMechanic from "./Pages/CarMechanic/CarMechanic";
import FrameStraightening from "./Pages/FrameStraightening/FrameStraightening ";
import Blog1 from "./Pages/Blog/Blog1";
import NotFound from "./Pages/NotFound/NotFound";
import Blog2 from "./Pages/Blog/Blog2";
import Sitemap from "./Pages/Sitemap/sitemap";
import Blog3 from "./Pages/Blog/Blog3";
import Blog4 from "./Pages/Blog/Blog4";
import Blog5 from "./Pages/Blog/Blog5";
import Blog6 from "./Pages/Blog/Blog6";
import Blog7 from "./Pages/Blog/Blog7";
import MercedesBenz from "./Pages/Certificates/MercedesBenz";
import Blog8 from "./Pages/Blog/Blog8";

const Routers = () => {
    // let [color, setColor] = useState("#18947A");

    // const Home = loadable(
    //     () =>
    //         new Promise((resolve, reject) =>
    //             setTimeout(() => resolve(import("./Pages/Home/Home")), 500)
    //         ),
    //     {
    //         fallback: <div className="spinner_block">
    //             <PuffLoader color={color} size={150}/>
    //         </div>
    //     }
    // );
    let str = "/fiberglass-repair/"
    return (
        <ScrollToTop>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/home"  element={<Navigate to='/'/>} />
                <Route path="/services" element={<Services/>}/>
                <Route path="/gallery" element={<Gallery/>}/>
                <Route path="/partners" element={<Partners/>}/>
                <Route path="/partenrs"  element={<Navigate to='/partners'/>} />
                <Route path="/certificates" element={<Certificates/>}/>
                <Route path="/careers" element={<Careers/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/tips-for-finding-reliable-mechanic" element={<Blog1/>}/>
                <Route path="/signs-of-bad-body-shop-paint-job" element={<Blog2/>}/>
                <Route path="/what-to-do-after-car-accident" element={<Blog3/>}/>
                <Route path="/why-is-my-car-vibrating" element={<Blog4/>}/>
                <Route path="/car-maintenance-and-care-tips" element={<Blog5/>}/>
                <Route path="/repair-or-to-replace-frame-rails" element={<Blog6/>}/>
                <Route path="/car-frame-damage" element={<Blog7/>}/>
                <Route path="/useful-tips-for-driving-long-distances"  element={<Navigate to='/car-frame-damage'/>} />
                <Route path="/tips-for-driving-long-distances" element={<Blog8/>}/>
                <Route path="/contact" element={<ContactUs/>}/>
                <Route path="/about" element={<AboutUs/>}/>
                <Route path="/faq" element={<Faq/>}/>
                <Route path="/term" element={<Term/>}/>
                <Route path="/privacy" element={<Privacy/>}/>
                <Route path="/auto-body-repair" element={<AutoBodyRepair/>}/>
                <Route path="/car-painting" element={<CarPainting/>}/>
                <Route path="/fiberglass-repair" element={<FiberglassRepair/>}/>
                {/*<Route path="/fiberglass-repair/"  element={<Navigate to={str.slice(0,-1)}/>} />*/}
                <Route path="/car-mechanic" element={<CarMechanic/>}/>
                <Route path="/vehicle-frame-repair" element={<FrameStraightening/>}/>
                <Route path="/sitemap" element={<Sitemap/>}/>
                <Route path="/certificates/mercedes-collision-center" element={<MercedesBenz/>}/>
                <Route path='/404'  element={<NotFound />}/>
                <Route path={"*"} element={<Navigate to='/404' /> }/>
            </Routes>
        </ScrollToTop>

    );
};

export default Routers;
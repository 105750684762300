import React from 'react';
import img1 from "../../Assets/Images/AboutUsitem.png"
import styles from "./AboutUs.module.scss"
const AboutUsItem = () => {
    return (
        <div className={styles.itemBlock}>
            <div>
                <h4>HEADING 1</h4>
                <div className={styles.text1}>
                    <p>Lorem ipsum dolor sit amet consectetur. Lacus phasellus congue diam neque semper. Purus egestas
                        mattis nibh velit in orci. A adipiscing pulvinar semper amet integer condimentum auctor duis mauris.
                        Vel blandit sit sagittis lectus. Vitae lectus id pellentesque venenatis ac eget proin velit
                        erat. </p>
                </div>
                <div className={styles.text2}>
                    <p>Lorem ipsum dolor sit amet consectetur. Lacus phasellus congue diam neque semper. Purus egestas
                        mattis nibh velit in orci. A adipiscing pulvinar semper amet integer condimentum auctor duis mauris.
                        Vel blandit sit sagittis lectus. Vitae lectus id pellentesque venenatis ac eget proin velit
                        erat. </p>
                </div>
            </div>
            <div className={styles.imgBlock}>
                <img src={img1} alt=""/>
            </div>
        </div>
    );
};

export default AboutUsItem;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Images/background_01.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerTerm {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 60px;
}
.containerTerm h1 {
  text-align: center;
  color: #FFFFFF;
  font-family: "hemi_head";
  display: inline-block;
  border-bottom: 1px solid #ED1C24;
}
.containerTerm .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 30px auto;
}
@media (max-width: 800px) {
  .containerTerm .wrapper {
    width: 95%;
  }
}
.containerTerm .wrapper h4 {
  color: #FFFFFF;
  font-family: "Roboto";
  font-size: 32px;
  margin: 0;
}
@media (max-width: 1200px) {
  .containerTerm .wrapper h4 {
    font-size: 24px;
  }
}
@media (max-width: 700px) {
  .containerTerm .wrapper h4 {
    font-size: 18px;
  }
}
.containerTerm .wrapper div p {
  color: #FFFFFF;
  font-size: 18px;
  font-family: "Roboto";
}`, "",{"version":3,"sources":["webpack://./src/Pages/Term/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,8BAAA;EACA,yDAAA;EACA,sBAAA;EACA,4BAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,cAAA;EACA,wBAAA;EACA,qBAAA;EACA,gCAAA;AACJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EAED,UAAA;EACC,iBAAA;AAAJ;AACI;EAPF;IAQI,UAAA;EAEJ;AACF;AADI;EACE,cAAA;EACA,qBAAA;EAEA,eAAA;EACA,SAAA;AAEN;AADM;EANF;IAOI,eAAA;EAIN;AACF;AAHM;EATF;IAUI,eAAA;EAMN;AACF;AAHM;EACE,cAAA;EACA,eAAA;EACA,qBAAA;AAKR","sourcesContent":[".containerTerm{\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  justify-content: space-between;\n  background-image: url(\"../../Assets/Images/background_01.png\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  margin-top: 60px;\n\n  h1{\n    text-align: center;\n    color: #FFFFFF;\n    font-family: \"hemi_head\";\n    display: inline-block;\n    border-bottom: 1px solid #ED1C24;\n    }\n  .wrapper{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    //justify-content: space-between;\n   width: 80%;\n    margin:30px auto;\n    @media (max-width: 800px) {\n      width: 95%;\n    }\n    h4{\n      color: #FFFFFF;\n      font-family: \"Roboto\";\n      //font-style: italic;\n      font-size: 32px;\n      margin: 0;\n      @media (max-width: 1200px) {\n        font-size: 24px\n      }\n      @media (max-width: 700px) {\n        font-size: 18px\n      }\n    }\n    div {\n      p {\n        color: #FFFFFF;\n        font-size: 18px;\n        font-family: \"Roboto\";\n      }\n    }\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

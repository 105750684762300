import React from 'react';
import classes from "./ServiceItem.module.scss"
const ServiceItem = ({img,title,text}) => {
    return (
        <div className={classes.serviceItem}>
            <div className={classes.serviceBox}>
                <img src={img} alt=""/>
                <div className={classes.textBlock}>
                    <h4>
                        {title}
                    </h4>
                    <p>{text}</p>
                </div>
            </div>
            <div className={classes.serviceLine}>

            </div>
        </div>
    );
};

export default ServiceItem;
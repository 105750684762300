import React, {useEffect} from 'react';
import './index.scss'
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";

const Index = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'Our Terms of Use outlines the guidelines and regulations for accessing and using our website. By using our website, you agree to comply with these terms.')
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Terms of Use | LA West</title>
                <meta name="description" content={"Our Terms of Use outlines the guidelines and regulations for accessing and using our website. By using our website, you agree to comply with these terms."} />
                <link rel="canonical" href={"https://www.lawestcollision.com/term"} />
            </Helmet>
        <div className="containerTerm">
            <div className="wrapper">
                <h1>Terms of Use</h1>
                <div>
                    <p>Acceptance of Terms</p>
                    <p>Welcome to our collision center's website. By accessing or using this website, you agree to comply with and be bound by the following terms and conditions of use ("Terms"). Please read these Terms carefully before using our website. If you do not agree to these Terms, you should not use our website.</p>
                    <p>Use of the Website</p>
                    <p style={{marginLeft: "1em"}}>a. You must be at least 18 years old to use this website.</p>
                    <p style={{marginLeft: "1em"}}>b. You agree to use this website for lawful purposes only.</p>
                    <p style={{marginLeft: "1em"}}>c. You are responsible for maintaining the confidentiality of your account information and password and for restricting access to your computer or device.</p>
                    <p style={{marginLeft: "1em"}}>d. You are responsible for all activities that occur under your account or password.</p>
                    <p>Intellectual Property Rights</p>
                    <p style={{marginLeft: "1em"}}>a. All content on this website, including but not limited to text, graphics, logos, images, audio clips, digital downloads, data compilations, and software, is the property of our collision center or its content suppliers and is protected by international copyright laws.</p>
                    <p style={{marginLeft: "1em"}}>b. You may not modify, reproduce, distribute, transmit, display, perform, publish, license, create derivative works from, transfer, or sell any information, software, products, or services obtained from this website without prior written consent from our collision center.</p>
                    <p>Limitations of Liability</p>
                    <p style={{marginLeft: "1em"}}>a. Our collision center strives to provide accurate and up-to-date information on this website. However, we make no warranties or representations about the accuracy, reliability, completeness, or timeliness of the content.</p>
                    <p style={{marginLeft: "1em"}}>b. In no event shall our collision center be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or performance of this website or the information, products, or services provided herein.</p>
                    <p style={{marginLeft: "1em"}}>c. You agree to indemnify and hold our collision center, its affiliates, employees, and agents harmless from any claims, losses, liabilities, expenses, or demands, including reasonable attorney's fees, arising out of your use of this website.</p>
                    <p>Third-Party Websites</p>
                    <p style={{marginLeft: "1em"}}>This website may contain links to third-party websites for your convenience. Our collision center does not endorse or control the content, products, or services offered by these third-party websites. We are not responsible for the accuracy, legality, or decency of any information, materials, products, or services displayed or provided by such websites.</p>
                    <p>Modification of Terms</p>
                    <p style={{marginLeft: "1em"}}>Our collision center reserves the right to modify these Terms at any time without prior notice. By continuing to use this website after any modifications, you agree to be bound by the updated Terms.</p>
                </div>
            </div>
        </div>
        </Layout>
    );
};

export default Index;
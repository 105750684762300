import React, {useEffect} from 'react';
import classes from "./Header.module.scss"
import {Container, Typography} from "@mui/material";

const Header = () => {
    return (
        <>
            <video className={classes.videoTag} loop autoPlay={true} muted={true} playsInline={true} >
                <source src="videohome.mp4" type='video/mp4'/>
            </video>
            <Container className={classes.Box}>
                {/*<Typography variant="h2">*/}
                {/*    LA WEST*/}
                {/*</Typography>*/}
                {/*<Typography variant="h1" className={classes.animation_txt}>Auto Body Shop in Los Angeles</Typography>*/}
            </Container>
        </>
    );
};

export default Header;
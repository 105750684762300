import React, {useEffect} from 'react';
import Layout from "../../Layouts/Layouts";
import styles from "./AutoBodyRepair.module.scss"
import img from "../../Assets/Images/service17.jpeg"
import {Helmet} from "react-helmet";
import {useMeta} from "../../MetaContext";

const AutoBodyRepair = () => {
    return (
        <Layout>
            <Helmet>
                <title>Auto Body Repair | Car Body Repair in Los Angeles | LA West</title>
                <script type="application/ld+json">
                    {
                        `{
  "@context": "https://schema.org",
  "@type": "Service",
  "provider": {
    "@type": "AutoBodyShop",
    "name": "LA West Collision Center",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "6827 Troost Ave North Hollywood, CA 91605",
      "addressLocality": "Los Angeles",
      "postalCode": "91605",
      "addressCountry": "US"
    },
    "telephone": "(818) 821-6033",
    "url": "https://www.lawestcollision.com",
    "logo": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg",
    "description": "LA West is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details.
"
  },
  "serviceType": "Auto Body Repair",
  "offers": {
    "@type": "Offer",
    "priceCurrency": "USD",
    "url": "https://www.lawestcollision.com/auto-body-repair"
  },
  "areaServed": {
    "@type": "City",
    "name": "Los Angeles"
  },
  "description": "Auto Body Repair service involves restoring a vehicle's exterior after accidents, dings, or wear and tear. This includes dent removal, paint touch-ups, and full panel replacements to bring the vehicle back to its original appearance."
}
`
                    }
                </script>

                <meta name="description"
                      content="Need car body repair in Los Angeles?  We have the latest tools and technology, let us provide you with professional & comprehensive Auto Body repair services."/>
                <meta name="keywords" content="auto body repair, autobody repair, car body repair"/>

                <link rel="canonical" href={"https://www.lawestcollision.com/auto-body-repair"}/>
            </Helmet>
            <div className={styles.container}>
                <h1>Auto Body Repair</h1>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <p>
                            The body of the car is the most expensive part of the vehicle. Yes, it’s even more expensive
                            than the engine. The performance of the car, as well as the safety of the driver and
                            passengers on the road, depends on the body of the car. Therefore, auto body repair must be
                            approached wisely.
                        </p>
                        <p>Auto Body repair is the process of restoring the casing to its nominal condition: smoothing
                            out dents and distortions, welding cracks, and removing rust.
                            The technology of car body repair includes the elimination of all defects: from the
                            opposition and small dents to the restoration of geometry.</p>
                        <p>You won't learn anything about auto body repair from anyone but a professional. It is not
                            advised to attempt the restoration yourself utilizing vacuum cleaners or hair dryer life
                            hacks that are popular online. It is best to learn as much as you can about Auto Body repair
                            before visiting the <a
                                href="https://lawestcollision.com/" target="_blank" style={{color: "#ededed"}}><b>auto
                                body shop</b></a> armed with that knowledge. It will be simpler to ask the specialist
                            clarifying questions and communicate with him as a result. </p>
                    </div>
                </div>
                {/*<div className={styles.itemBlock}>*/}
                {/*    <div>*/}
                {/*        <h2>Features of The Repair of The Luggage Compartment Cover</h2>*/}
                {/*        <div className={styles.text1}>*/}
                {/*            <p>Damage to the trunk lid is very common in urban car accidents. The driver behind gaped a*/}
                {/*                little - and there was a solid dent on the top. They are especially noticeable on*/}
                {/*                station wagons. </p>*/}
                {/*        </div>*/}
                {/*        <div className={styles.text2}>*/}
                {/*            <p>Therefore, luggage repair is a very popular service. Specialists restore the original*/}
                {/*                shape of the part. In this case, it is not at all necessary to remove the lid itself -*/}
                {/*                minor damage is perfectly “healed” without dismantling. The exception is only deep dents or damage to the stiffeners - then the fifth door is removed.</p>*/}
                {/*        </div>*/}
                {/*        <div className={styles.text1}>*/}
                {/*            <p>Repairing the luggage compartment allows you to save up to 70% of the funds that could be required for the purchase and installation of a new part. But in some cases, it is cheaper to install a new cap anyway, for example, in case of serious damage to the fifth door of the station wagon with severe deformation of the stiffeners.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div className={styles.text2}>*/}
                {/*            <p>*/}
                {/*                Often, even with minor damage to the rear of the car, the luggage lock will fade. Therefore, together with the repair of the roof, they are repairing the luggage lock. If the grooves for the lock remain intact, then only the inner part of the mechanism is changed. Otherwise, a new lock is installed during the repair process.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Types of Auto Body Repair</h2>
                        <div className={styles.text1}>
                            <p>If there is serious damage to the paintwork, pitting and geometry violations and certain
                                areas cannot be restored, auto body repair work becomes much more complicated. It is
                                almost impossible to do without a painting, however, the complexity of the activities
                                performed may vary depending on the nature of the damage. In most cases, local repair of
                                the car body is carried out. It requires the least time and has the lowest price. In
                                addition, the advantages of this technology include the absence of the need to dismantle
                                the part and the exact color match, as well as the absence of visible boundaries between
                                the finished areas and the factory coating.</p>
                        </div>
                        <div className={styles.text2}>
                            <p>In addition to local auto body repair, the specialists of our car service also perform
                                complex operations using welding and slipway equipment to restore broken geometry. In
                                addition, the list of services provided also includes interior cleaning, polishing,
                                hauling of individual elements, and much more.</p></div>
                        <div className={styles.text1}>
                            <p>LA West collision center is providing a professional auto body repair service. After the
                                first consultation with the masters, you will be able to fully understand what is
                                included in the body repair, and how long it will take. Specially designated room for
                                aluminum repairs to eliminate the factor of outside particles interfering with the
                                repairs and materials being used to complete the highest repair quality. Minor Dented &
                                Scratched wheels are repairable by our team of experienced technicians. All wheels are
                                applicable (Powder Coat, Chrome, Allow, Forged, 3 Piece ETC.). I-Car Certified
                                technicians completing mechanical repairs exceeding the industry's standards. Custom
                                curation for the repairs of special material vehicles with the industry's latest
                                equipment.</p>
                        </div>
                        <div className={styles.text2}>
                            <p>
                                We are also providing services such as <a
                                href="https://lawestcollision.com/car-painting" style={{color: "#ededed"}}
                                target="_blank"><b>auto paint</b></a>, fiberglass repair, frame straightening, etc. With
                                LA West your car will be healthy!
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    );
};

export default AutoBodyRepair;
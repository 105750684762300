import React from 'react';
import classes from "./Blog.module.scss"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/blog3.jpg";
import img2 from "../../Assets/Images/mechanicalrepair.jpg";
import img3 from "../../Assets/Images/wheelrepair.jpg";
import img1 from "../../Assets/Images/Aluminium.jpg";
import img5 from "../../Assets/Images/Advancedpaintless.jpg";
import img4 from "../../Assets/Images/towingservice.jpg";

const Blog3 = () => {
    return (
        <Layout>
            <Helmet>
                <title>What to Do After a Car Accident: A Step-by-Step Guide</title>
                <meta name="description" content={"After a car accident, follow this step-by-step guide for a smooth recovery. Learn how to handle insurance, injuries, and more. Your comprehensive resource."} />
                <script type="application/ld+json">
                    {
                        `
                    {
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": "https://www.lawestcollision.com/what-to-do-after-car-accident"
                    },
                        "headline": "What to Do After a Car Accident: A Step-by-Step Guide",
                        "description": "After a car accident, follow this step-by-step guide for a smooth recovery. Learn how to handle insurance, injuries, and more. Your comprehensive resource.",
                        "image": {
                        "@type":"ImageObject",
                        "url":"https://www.lawestcollision.com/static/media/mechanicalrepair.066822676391a62d83ad.jpg",
                        "width":800,
                        "height":600
                        },
                        "author": {
                        "@type": "Organization",
                        "name": "LA West"
                    },
                        "publisher": {
                        "@type": "Organization",
                        "name": "La West",
                        "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg"
                    }
                    },
                        "datePublished": "2023-07-01",
                        "dateModified": "2023-07-01"
                    }`
                    }
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/what-to-do-after-car-accident"} />
            </Helmet>

            <div className={classes.textBlog1}>
                <h1 className={classes.titleBlog}>Important Steps to Take After a Car Crash</h1>
            </div>
            <div className={styles.itemBlock}>
                <div>
                    <div className={styles.text1}>
                        <p>Driving a car is not only a pleasant experience. There are a lot of traffic accidents on the
                            roads every year, and most people don't even know what to do after a car accident. If you
                            actively use the car, you will have an accident sooner or later. Accuracy and good driving
                            skills reduce the risk but only partially eliminate it. And it is better to know what to do
                            after a car accident to avoid mistakes. In the unfortunate event of a car accident, one crucial step is finding a reputable <a
                                href="https://www.lawestcollision.com" style={{color:"#eee"}} target={"_blank"}><b>auto body shop.</b></a></p>
                    </div>
                    <div className={styles.text2}>
                        <p>Well, now we know, neither the observance of traffic rules, the knowledge gained in
                            accident-free driving courses, nor even many years of driving experience can guarantee of
                            happening an accident. In order to act calmly and confidently in a stressful situation, it
                            is essential to know what to do in a car accident and prepare for it in advance. We tell you
                            how to behave after an accident, what happens when a car hits you from the side, and
                            everything about what to do after a car accident.
                        </p></div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.mainInfo} style={{marginTop: "30px"}}>
                    <div>
                        <h2>What to do in a Car Accident</h2>
                        <p>
                            If you are involved in a collision you should keep your composure and maintain peace, no
                            matter what happens! After an accident, try not to panic or let your feelings rule your
                            actions. Stress often causes drivers to make poor decisions, such as simply driving away (a
                            reflex reaction is an escape from danger). Additionally, abandoning the site of an accident
                            carries a harsh penalty. Therefore, you must come to a complete stop, activate your hazard
                            warning lights, and install a warning triangle. Items connected to the incident shouldn't be
                            moved. If you have an accident in which someone is injured, you should treat them with first
                            aid. Take action to bring them to a hospital as quickly as you can in an emergency. Next,
                            snap photos of the accident scene from various perspectives. Include in your photos not only
                            the automobiles but also any markings, traffic signs, braking distances, and vehicle
                            identification numbers. It is suggested that shooting be done while the geolocation mode is
                            enabled.
                        </p>
                    </div>
                    <img src={img2} alt=""/>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "30px"}}>
                    <img src={img3} alt=""/>
                    <div>
                        <h2>Assessment of the Situation</h2>
                        <p>
                            After the mandatory procedures described above are completed, it is worth assessing the
                            damage to the machines. If flammable liquids leak out of them, remove a fire extinguisher
                            from the trunk in advance and call the firefighters: even if there is no fire, the
                            firefighters will have to wash the fuel and oil off the asphalt. In such situations, consider reaching out to a professional <a
                            href="https://www.lawestcollision.com/auto-body-repair" target={"_blank"} style={{color:"#eee"}}><b>auto body repair</b></a> service. And in order not to have to
                            put out the cars, de-energize them by removing the terminals from the batteries. And, of
                            course, do not smoke near the scene of an accident.

                        </p>
                        <p>In the process of assessing losses, talk calmly with your counterparts. Do not make claims
                            and avoid conflicts, because, in a stressful situation, many people act inappropriately. The
                            kindest person under normal conditions, under the influence of adrenaline, can behave
                            aggressively and not accept arguments. If the dialogue does not add up, do not get into a
                            skirmish - lock yourself in the car and call the police.</p>

                    </div>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "30px", marginBottom: "30px"}}>
                    <img src={img5} alt=""/>
                    <div>
                        <h2>What If No One Involved in The Accident Pleads Guilty:</h2>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>We strongly recommend that you wait for the traffic police to fix all the details of
                                this accident, or call an emergency commissioner or our auto lawyer. Specialists must
                                fix the position of cars on the road relative to the boundaries of the carriageway with
                                an accuracy of up to a centimeter, and preferably up to millimeters; make accurate
                                measurements and fixation of the trajectories of braking marks; write down the contacts
                                of witnesses; take video from registrars.
                            </li>
                            <li>After all the procedures at the scene of an accident, you must go to the traffic police
                                and fill out an explanation. It is desirable that witnesses also testify to the traffic
                                police in writing. Write everything as detailed as possible, indicate how you moved, in
                                which lane, and at what speed. Be sure to tell when the turn signal was turned on and
                                off, how other road users moved relative to you, how and when you felt or saw the
                                collision, and how you moved after the collision.

                            </li>
                            <li>As a rule, after testifying in the event of a dispute over fault, traffic police
                                officers make a determination that the degree of fault in an accident should be
                                determined in court.
                            </li>
                            <li>If you are not satisfied with the decision of the traffic police, take pictures of the
                                explanations of all participants in the accident, including the explanations of
                                witnesses, and the accident diagram, and contact a car lawyer. Even if you were found
                                guilty by the traffic police, your guilt could only be established in court.
                            </li>
                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <h2>If the culprit fled in a meta accident:</h2>
                        <p>
                            Try to remember, but it is better to fix the license plate number of the hidden car. Look
                            for witnesses and write down their contact details. Take as many photos of the accident
                            scene as you can. Draw up an accident plan. Call the traffic police to fix the accident and
                            go to the traffic police to write an explanation.

                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo}>
                    <img src={img1} alt=""/>
                    <div>
                        <h2>Accident With Injured/dead. </h2>
                        <p>
                            Call an ambulance and the traffic police. Do not remove cars from the scene of an accident
                            before the arrival of the traffic police. Make sure to take photos and videos. Write down
                            the contact details of the witnesses. In the future, follow the instructions of the traffic
                            police. If you do not agree with the scheme drawn up by the traffic police, then in the
                            scheme write "disagree with the scheme" and your signature. Enlist the help of an expert
                            auto technician or a car lawyer, and preferably both.

                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "30px", marginBottom: "30px"}}>
                    <img src={img4} alt=""/>
                    <div>
                        <h2>What If No One Involved in The Accident Pleads Guilty:</h2>
                        <p>Let's summarize the results and write down the procedure for actions of what to do in a car
                            accident.
                        </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>Stop the car, and turn off the engine.</li>
                            <li>Turn on the emergency alarm, and set the emergency stop sign. If necessary (at night, in
                                fog), put on a reflective vest and light a flare.

                            </li>
                            <li>Make sure no one is injured in the accident. If necessary, call emergency services and
                                provide assistance.
                            </li>
                            <li>Write down the names and telephone numbers of any witnesses to the accident.
                            </li>
                            <li>Assess the damage to the cars, and discuss the situation with the second driver. If you
                                interfere with traffic, record the accident on a photo/video and pull over to the side
                                of the road.
                            </li>
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Blog3;
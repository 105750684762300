import React, {useEffect} from 'react';
import Layout from "../../Layouts/Layouts";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/rmpaint.webp"
import {Helmet} from "react-helmet";

const CarPainting = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'Need car painting in Los Angeles? Let LA West transform your vehicle with a new coat of paint. Our paint services make your vehicle look new again. ')
    //     let themeMeta=document.querySelector("meta[name='keywords']")
    //     themeMeta.setAttribute("content", "car painting, auto paint, auto body paint, car paint shop")
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Car Painting | Auto Paint in Los Angeles, CA | LA West</title>
                <meta name="description"  content="Need car painting in Los Angeles? Let LA West transform your vehicle with a new coat of paint. Our paint services make your vehicle look new again. "/>
                <meta name="keywords" content="car painting, auto paint, auto body paint, car paint shop"/>
                <script type="application/ld+json">
                    {`
                    {
  "@context": "https://schema.org",
  "@type": "Service",
  "provider": {
    "@type": "AutoBodyShop",
    "name": "LA West Collision Center",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "6827 Troost Ave North Hollywood, CA 91605",
      "addressLocality": "Los Angeles",
      "postalCode": "91605",
      "addressCountry": "US"
    },
    "telephone": "(818) 821-6033",
    "url": "https://www.lawestcollision.com",
    "logo": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg",
    "description": "LA West is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details.
"
  },
  "serviceType": "Car Painting",
  "offers": {
    "@type": "Offer",
    "priceCurrency": "USD",
    "url": "https://www.lawestcollision.com/car-painting"
  },
  "areaServed": {
    "@type": "City",
    "name": "Los Angeles"
  },
  "description": "Car Painting Repair service focuses on rejuvenating a vehicle's exterior by addressing chipped, faded, or damaged paint. Professionals meticulously match the original color, ensuring a seamless finish, thereby enhancing the vehicle's aesthetic and protecting its surface."
}

                     `}
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/car-painting"} />
            </Helmet>
            <div className={styles.container}>
                <h1>Car Painting</h1>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <p>
                            High-quality complete car painting is a real art that requires high qualifications and
                            experience from the master. The appearance of the car will also be affected by the materials
                            and equipment used. Modern technologies, such as computer-assisted selection of enamel, make
                            it possible to obtain the desired color and even shade.
                        </p>
                        <p>To complete car painting and do it efficiently, you need to disassemble the body into parts
                            and remove bumpers and other elements from it. After that, the optimal color, shade, and
                            texture are selected. Only now you can proceed directly to car painting.
                        </p>
                        <p>As a rule, appropriate materials and technologies are selected for each method of auto paint.
                            LA West auto body shop is using the industry’s most harmless and toxin-free water-based
                            paint to maximize the safety of our valued clients and restore the dealer showroom shine.
                        </p>
                        <p>That is why very high demands are placed on the auto body paint and the quality of the auto
                            paint itself, regardless of whether part or the entire car was painted.
                        </p>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Why do you need car painting?</h2>
                        <div className={styles.text1}>
                            <p>The answer to the question of why you should apply to a car paint shop or why you needed
                                it, is quite obvious - the old coating can wear out or be damaged due to impact. It is
                                very difficult to restore the paintwork of a car, but it must be done, otherwise, the
                                bare metal will begin to collapse. And the question of aesthetics is not in last place.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p style={{textAlign: "left"}}>For high-quality paint application, several preliminary
                                bodywork operations should be
                                performed. The following steps need to be noted:
                                <li style={{textIndent: "20px"}}>In the selected area, you need to remove the remnants
                                    of the old coating. When
                                    performing large-scale repainting, this process may be delayed.
                                </li>
                                <li style={{textIndent: "20px"}}>Next, you need to proceed to the elimination of
                                    mechanical and corrosion damage.
                                    Rust must be removed with sandpaper, and dents and cracks will need to be repaired
                                    with putty. The most serious damage to the metal is corrected by welding,
                                    straightening, and grinding, followed by the application of putty.
                                </li>
                                <li style={{textIndent: "20px"}}>In order for the paint to be well-light on putty, a
                                    primer should be applied. It is
                                    also designed to protect the metal from the spread of corrosive processes. Before
                                    applying paint, the treated surface should be sanded with sandpaper and degreased.
                                </li>
                            </p>
                        </div>
                        <div className={styles.text1}>
                            <p>To prevent paint from getting into unnecessary areas, it must be protected or removed if
                                it is a removable part. You can protect the area with paper.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Where is the best place to paint?</h2>
                        <div className={styles.text1}>
                            <p>
                                Many factors influence the quality of the painting. This is the quality of the
                                equipment, the correct implementation of the preparation, and the sterility of the room.
                                When painting in garage conditions, problems can arise at various stages - at the stage
                                of selecting a paint color or at the stage of repairing cracks with putty. In this
                                sense, it is better to entrust this process to professionals who understand the
                                specifics of car body painting, from the choice of material to the application of
                                varnish.
                            </p>
                        </div>
                        <div className={styles.text2}>
                            <p>
                               At LA West <a href="https://lawestcollision.com/" style={{color: "#ededed"}}
                                              target="_blank"><b>Auto Body Shop</b></a> you will find professionals, who
                                are qualified not only as a painter but also in  <a href="https://lawestcollision.com/fiberglass-repair/" style={{color:"#ededed"}} target="_blank"><b>fiberglass repair</b></a>, frame straightening,
                                etc. Give life to your car with LA West!
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default CarPainting;
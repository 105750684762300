// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Images/line.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServiceItem_serviceItem__f1y4\\+ {
  width: 100%;
}
.ServiceItem_serviceItem__f1y4\\+ .ServiceItem_serviceBox__AnZUC {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  flex-wrap: wrap;
}
@media (max-width: 1050px) {
  .ServiceItem_serviceItem__f1y4\\+ .ServiceItem_serviceBox__AnZUC {
    justify-content: center;
  }
}
.ServiceItem_serviceItem__f1y4\\+ .ServiceItem_serviceBox__AnZUC img {
  width: 343px;
  border: 2px solid #FFFFFF;
}
@media (max-width: 500px) {
  .ServiceItem_serviceItem__f1y4\\+ .ServiceItem_serviceBox__AnZUC img {
    width: 100%;
  }
}
.ServiceItem_serviceItem__f1y4\\+ .ServiceItem_textBlock__90Hp1 {
  max-width: 700px;
  text-align: center;
}
@media (max-width: 1380px) {
  .ServiceItem_serviceItem__f1y4\\+ .ServiceItem_textBlock__90Hp1 {
    max-width: 450px;
  }
}
.ServiceItem_serviceItem__f1y4\\+ .ServiceItem_textBlock__90Hp1 h4 {
  font-family: "hemi_head";
  color: #FFFFFF;
  font-size: 40px;
}
@media (max-width: 1380px) {
  .ServiceItem_serviceItem__f1y4\\+ .ServiceItem_textBlock__90Hp1 h4 {
    font-size: 30px;
  }
}
.ServiceItem_serviceItem__f1y4\\+ .ServiceItem_textBlock__90Hp1 p {
  color: #FFFFFF;
  font-size: 40px;
  text-align: center;
}
@media (max-width: 1380px) {
  .ServiceItem_serviceItem__f1y4\\+ .ServiceItem_textBlock__90Hp1 p {
    font-size: 30px;
  }
}
.ServiceItem_serviceItem__f1y4\\+ .ServiceItem_serviceLine__Iyevb {
  height: 320px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 800px) {
  .ServiceItem_serviceItem__f1y4\\+ .ServiceItem_serviceLine__Iyevb {
    height: 150px;
  }
}
.ServiceItem_serviceItem__f1y4\\+:nth-child(2n+1) div {
  flex-direction: row-reverse;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Services/ServiceItem.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;AAEJ;AADI;EATF;IAUG,uBAAA;EAIH;AACF;AAHI;EACE,YAAA;EACA,yBAAA;AAKN;AAJM;EAHF;IAII,WAAA;EAON;AACF;AAJE;EACE,gBAAA;EACA,kBAAA;AAMJ;AALI;EAHF;IAII,gBAAA;EAQJ;AACF;AAPI;EACE,wBAAA;EACA,cAAA;EACA,eAAA;AASN;AARM;EAJF;IAKI,eAAA;EAWN;AACF;AARI;EACE,cAAA;EACA,eAAA;EACA,kBAAA;AAUN;AATM;EAJF;IAKI,eAAA;EAYN;AACF;AATE;EACE,aAAA;EACA,mDAAA;EACA,qBAAA;EACA,2BAAA;EACA,4BAAA;AAWJ;AAVI;EANF;IAOG,aAAA;EAaH;AACF;AAXE;EACE,2BAAA;AAaJ","sourcesContent":[".serviceItem {\n  width: 100%;\n  .serviceBox{\n    width: 80%;\n    margin:auto;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    padding: 50px 20px;\n    flex-wrap: wrap;\n    @media (max-width: 1050px) {\n     justify-content: center;\n    }\n    img{\n      width: 343px;\n      border: 2px solid #FFFFFF;\n      @media (max-width: 500px) {\n        width: 100%;\n      }\n    }\n  }\n  .textBlock {\n    max-width: 700px;\n    text-align: center;\n    @media (max-width: 1380px) {\n      max-width: 450px;\n    }\n    h4 {\n      font-family: \"hemi_head\";\n      color: #FFFFFF;\n      font-size: 40px;\n      @media (max-width: 1380px) {\n        font-size: 30px;\n      }\n    }\n\n    p {\n      color: #FFFFFF;\n      font-size: 40px;\n      text-align: center;\n      @media (max-width: 1380px) {\n        font-size: 30px;\n      }\n    }\n  }\n  .serviceLine{\n    height: 320px;\n    background: url(\"../../Assets/Images/line.png\");\n    background-size: 110%;\n    background-position: center;\n    background-repeat: no-repeat;\n    @media (max-width: 800px) {\n     height: 150px;\n    }\n  }\n  &:nth-child( 2n+1 ) div{\n    flex-direction: row-reverse;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceItem": `ServiceItem_serviceItem__f1y4+`,
	"serviceBox": `ServiceItem_serviceBox__AnZUC`,
	"textBlock": `ServiceItem_textBlock__90Hp1`,
	"serviceLine": `ServiceItem_serviceLine__Iyevb`
};
export default ___CSS_LOADER_EXPORT___;

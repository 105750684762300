import React, {useEffect} from 'react';
import PartnersList from "./PartnersList";
import styles from "./Partners.module.scss"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";

const Partners = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'At LA West Collision Center, we are proud to work with the best partners in the industry to deliver top-notch auto body repair services. Trust us!')
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Partners | LA West</title>
                <meta name="description"
                      content="At LA West Collision Center, we are proud to work with the best partners in the industry to deliver top-notch auto body repair services. Trust us!"/>
                <link rel="canonical" href={"https://www.lawestcollision.com/partners"} />

            </Helmet>
            <div className={styles.title}>
              <h1>OUR PARTNERS</h1>
            </div>
            {/*<Slider />*/}
            {/*<div className={styles.partnersHeader}>*/}
            {/*    <div className={styles.layer}></div>*/}
            {/*</div>*/}
            <video className={styles.videoTag} loop autoPlay={true} muted={true} playsInline={true} >
                <source src="partnersvideo.mp4" type='video/mp4'/>
            </video>
            <PartnersList />
        </Layout>
    );
};

export default Partners;
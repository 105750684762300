// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat:300);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_notFound__HpiXt {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  width: 80%;
  margin: auto;
  max-height: 100vh;
}
.NotFound_notFound__HpiXt h1 {
  font-size: 30vh;
  margin: 0;
}
.NotFound_notFound__HpiXt h2 span {
  font-size: 4rem;
  font-weight: 600;
  color: #ED1C24;
}
.NotFound_notFound__HpiXt a:link, .NotFound_notFound__HpiXt a:visited {
  text-decoration: none;
  color: #fff;
}
.NotFound_notFound__HpiXt h3 a {
  padding: 10px;
  box-sizing: border-box;
  transition: 0.3s all;
  border-radius: 10px;
}
.NotFound_notFound__HpiXt h3 a:hover {
  text-decoration: none;
  background: #fff;
  cursor: pointer;
  color: #0A0A0A;
  transition: 0.3s all;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NotFound/NotFound.module.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,qCAAA;EACA,eAAA;EACA,UAAA;EACA,YAAA;EACA,iBAAA;AACF;AAAE;EACE,eAAA;EACA,SAAA;AAEJ;AAAE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAEJ;AAAE;EACE,qBAAA;EACA,WAAA;AAEJ;AAAE;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;EACA,mBAAA;AAEJ;AAAE;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,oBAAA;AAEJ","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Montserrat:300');\n.notFound{\n  color: #fff;\n  font-family: 'Montserrat', sans-serif;\n  font-size: 16px;\n  width: 80%;\n  margin:auto;\n  max-height: 100vh;\n  h1{\n    font-size: 30vh;\n    margin: 0;\n  }\n  h2 span{\n    font-size: 4rem;\n    font-weight: 600;\n    color: #ED1C24;\n  }\n  a:link, a:visited{\n    text-decoration: none;\n    color: #fff;\n  }\n  h3 a{\n    padding: 10px;\n    box-sizing: border-box;\n    transition: 0.3s all;\n    border-radius: 10px;\n  }\n  h3 a:hover{\n    text-decoration: none;\n    background: #fff;\n    cursor: pointer;\n    color: #0A0A0A;\n    transition: 0.3s all;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": `NotFound_notFound__HpiXt`
};
export default ___CSS_LOADER_EXPORT___;

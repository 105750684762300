import React, {useEffect} from 'react';
import './index.scss'
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";

const Index = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'At LA West, we take your privacy seriously. Our Privacy Policy outlines how we collect, use, and protect any information you provide to us.')
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Privacy Policy | LA West</title>
                <meta name="description" content={"At LA West, we take your privacy seriously. Our Privacy Policy outlines how we collect, use, and protect any information you provide to us."} />
                <link rel="canonical" href={"https://www.lawestcollision.com/privacy"} />
            </Helmet>
        <div className="containerTerm">
            <div className="wrapper">
                <h1>Privacy Policy</h1>
                <div>
                    <p>Collection of Personal Information</p>
                    <p style={{marginLeft: "1em"}}>a. We may collect personal information from you when you voluntarily provide it through our website, such as when you submit a contact form or request a service.</p>
                    <p style={{marginLeft: "1em"}}>b. The personal information we collect may include your name, email address, phone number, and any other information you choose to provide.</p>
                    <p>Use of Personal Information</p>
                    <p style={{marginLeft: "1em"}}>a. We may use the personal information we collect to respond to your inquiries, provide services you request, improve our website and services, and communicate with you about promotions or updates.</p>
                    <p style={{marginLeft: "1em"}}>b. We will not sell, trade, or transfer your personal information to third parties without your consent, except as required by law or as necessary to fulfill your requests.</p>
                    <p>Cookies and Tracking Technologies</p>
                    <p style={{marginLeft: "1em"}}>a. Our website may use cookies and other tracking technologies to enhance your browsing experience. Cookies are small files that are stored on your computer's hard drive and help us analyze web traffic or recognize your preferences.</p>
                    <p style={{marginLeft: "1em"}}>b. You may choose to disable cookies through your browser settings, but this may limit your access to certain features of our website.</p>
                    <p></p>
                    <p style={{marginLeft: "1em"}}>We take reasonable measures to protect the personal information you provide to us against unauthorized access, alteration, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                    <p>Children's Privacy</p>
                    <p style={{marginLeft: "1em"}}>Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected information from a child under 13, please contact us, and we will promptly delete the information.</p>
                    <p>Changes to the Privacy Policy</p>
                    <p style={{marginLeft: "1em"}}>We reserve the right to update or change this Privacy Policy at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our website after the changes constitute your acceptance of the revised Privacy Policy.</p>
                </div>
            </div>
        </div>
        </Layout>
    );
};

export default Index;
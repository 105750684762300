import React from 'react';
import classes from "./Blog.module.scss"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/blog55.jpg";
import img1 from "../../Assets/Images/blog33.jpg";
import img2 from "../../Assets/Images/mechanicalrepair.jpg";

const Blog6 = () => {
    return (
        <Layout>
            <Helmet>
                <title>Frame Rail: Repair vs. Replacement for Vehicle Safety</title>
                <meta name="description"
                      content={"Ensure your vehicle's safety with frame rail repair or replacement. LA West blog provides expert insights on when to repair and when to replace frame rails."}/>
                <script type="application/ld+json">
                    {
                        `
                    {
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": "https://www.lawestcollision.com/repair-or-to-replace-frame-rails"
                    },
                        "headline": "Frame Rail: Repair vs. Replacement for Vehicle Safety",
                        "description": "Ensure your vehicle's safety with frame rail repair or replacement. LA West blog provides expert insights on when to repair and when to replace frame rails.",
                        "image": {
                        "@type":"ImageObject",
                        "url":"https://www.lawestcollision.com/static/media/blog55.e03a0a02b651c1f08da7.jpg",
                        "width":800,
                        "height":600
                        },
                        "author": {
                        "@type": "Organization",
                        "name": "LA West"
                    },
                        "publisher": {
                        "@type": "Organization",
                        "name": "La West",
                        "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg"
                    }
                    },
                        "datePublished": "2023-10-24",
                        "dateModified": "2023-10-24"
                    }`
                    }
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/repair-or-to-replace-frame-rails"}/>
            </Helmet>

            <div className={classes.textBlog1}>
                <h1 className={classes.titleBlog}>Frame Rail: Repair or Replace? Do you Need to Replace the Frame Rail
                    on a Car?</h1>
            </div>
            <div className={styles.itemBlock}>
                <div>
                    <div className={styles.text1}>
                        <p>Frame rail restoration is a task that requires quality. Not all auto services in <a
                            href="https://www.lawestcollision.com/" style={{color:"#eee"}} target={"_blank"}><b>auto body
                            shops</b></a> can cope with this task. If this power structure is rotten, cracks appear on its
                            components, or the metal is damaged due to a head-on collision with another car. It is
                            imperative to carry out a quality frame rail repair. Believe us, in 80% of cases only good
                            mechanics cope with <a href="https://www.lawestcollision.com/vehicle-frame-repair" style={{color:"#eee"}} target={"_blank"}><b>car frame repairs</b></a>. So the first question, in this case, is how to fix a
                            bent frame on a car or how much frame repair costs.
                        </p></div>
                    <div className={styles.text2}>
                        <p>The frame rail carries almost the entire structure of the car. Body parts, motor, gearbox,
                            drives, and other elements are attached to it. With intensive use of the car, its frame may
                            break while driving. Regular inspection of the frame and timely repair of frames helps to
                            avoid this.
                        </p></div>
                    <div className={styles.text1}>
                        <p>Frame damage is common and varied like cracks, folds, and deformation. Most of them can be
                            repaired. It is pointless to bring to life only a frame that has been bent after severe
                            accidents.

                        </p>
                    </div>
                    <div className={styles.text2}>
                        <p>So what is the frame of the car and how important it is we’ve already figured it out. Now we
                            will consider the technologies and stages of frame repair.

                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.mainInfo}
                     style={{marginTop: "50px", marginBottom: "50px", flexDirection: 'column', alignItems: "start"}}>
                    <div style={{
                        width: "100%", display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <div>
                            <h2> Can You Fix a Bent Frame On a Car?</h2>
                            <p>If the frame of the car is bent, what to do? The curvature of the longitudinal beams of
                                the frame in the horizontal and vertical planes causes the displacement of the axes of
                                individual units and disrupts their normal operation. The presence of curvature is
                                usually determined by the eye using a meter ruler. Bent places are straightened in a
                                cold state with special devices.
                                Straightened places are checked by applying a meter ruler to the frame beam. Curvature
                                and cracks appear as a result of improper operation of the car (overloading, improper
                                towing, etc.), excessive stiffness of the springs, and metal fatigue.

                            </p>

                            <p>The curvature of the front fangs causes the displacement of the front springs and, as a
                                result, increased tire wear and difficulty in driving (pulls to the side). To check the
                                presence and nature of the curvature of the fangs, a metal straight rod is passed into
                                the holes of the right and left spring brackets.

                            </p>
                            <p>The diameter of the rod must match the diameter of the holes in the brackets. If the rod
                                passes freely into the holes, then the fangs are not bent. Otherwise, they need to be
                                corrected. For editing, the bent place is covered with charcoal, which is kindled with a
                                blowtorch. After warming up, the fangs are corrected with a jack and chain or a special
                                straightening press. Now we know that when the frame of the car is bent we can fix it.
                            </p>
                        </div>
                        <img src={img2} alt=""/>
                    </div>
                    <div style={{width: "100%", textAlign: "left"}}>
                        <h3>Frame Diagnostics </h3>
                        <p style={{width: "80%", textAlign: "left"}}>
                            Before repair, it is necessary to diagnose the condition of the frame. You can check for
                            cracks with a video endoscope. In the absence of a professional tool, you can use the USB
                            endoscope, which is connected to a laptop or tablet.

                        </p>
                        <p style={{width: "80%", textAlign: "left"}}>You can check the frame for distortion by diagonal
                            measurements between the control points. So you find out in which direction the frame led.
                            It is possible to determine the straightness of the carrier system on long lengths using
                            different methods. The essence of each is to define a straight line as the initial
                            coordinate and compare the frame with it.
                        </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>Laser. An accurate method in which you set up a laser at one end of the frame and direct
                                the beam to the opposite end of the structure. A piece of flat material, such as
                                cardboard, is held along the frame at several points. Record and compare all
                                measurements - whether they match at all points. Data mismatch indicates frame
                                distortion. Similarly, check the height - if the measurements deviate up or down, you
                                can talk about the bending of the frame.

                            </li>
                            <li>Cord. This method is an exact repetition of the laser manipulations described above.
                                Only the role of the beam is performed by the cord.

                            </li>
                            <li>Plumb and cord. It is convenient to make measurements with a plumb line when there are
                                many obstacles along the frame. With a plumb line, you mark points on the floor and
                                connect these points with a cord. On the bottom of the beams, determine the symmetrical
                                points, from the outside which lower the plumb line. Mark the floor with chalk. Connect
                                the dots, and then analyze - if all the diagonals intersect the center line, then it is
                                even.

                            </li>
                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "50px", marginBottom: "50px",flexDirection: 'column', alignItems: "start"}}>
                    <div  style={{
                        width: "100%", display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <img src={img1} alt=""/>
                    <div>
                        <h2>What to Do If The Frame Is Cracked </h2>
                        <p>
                            When repairing cargo frames, it is often necessary to eliminate cracks. Imagine that you are
                            bending and unbending some material. Sooner or later, it reaches the limit of its elasticity
                            and cracks. The same thing happens to the frame of a car when it is subjected to cyclic
                            loading and unloading. The metal experiences fatigue and cracks. Minor damage is welded
                            without additional reinforcement, serious damage is welded with overlays.

                        </p>
                        <p>But before proceeding with welding work, the damaged area must be prepared.
                        </p>
                    </div>
                    </div>
                    <div style={{width: "100%", textAlign: "left"}}>
                        <h3>Stages in welding work </h3>
                        <p style={{width: "80%", textAlign: "left"}}>
                            Preparation for welding. Before repair, ensure free access to the damaged section of the load-bearing frame. If necessary, dismantle the parts that stand in the way. To minimize the load on the frame during repairs, it is also worth removing all attachments.

                        </p>
                        <p style={{width: "80%", textAlign: "left"}}>
                            The basic preparation for welding consists of the following steps:

                        </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>Drill a hole at the end of the crack with a drill to a depth of approx. It is necessary so that the crack does not go further along the metal.
                            </li>
                            <li>Cut the edges approximately at a right angle for ⅔ of the thickness of the part. For cutting, a grinder or a plasma cutter is suitable.
                        </li>
                        </p>
                    </div>
                    <div style={{width: "100%", textAlign: "left"}}>
                        <h3>How to cut a frame  </h3>
                        <p style={{width: "80%", textAlign: "left"}}>
                            Do not make seams perpendicular to the spar. Otherwise, zones with stress concentration will not be created after welding. This may lead to new cracks.

                        </p>
                        <p style={{width: "80%", textAlign: "left"}}>
                            You can make cuts at an angle of 30, or 45 degrees, in the form of the letter Z. A large welding area will help distribute the load over a large surface. So the repaired frame will last longer. To mark the cut points, it is convenient to use masking tape - it is easy to draw on it.
                        </p>
                    </div>
                    <div style={{width: "100%", textAlign: "left"}}>
                        <h3> Welding process  </h3>
                        <p style={{width: "80%", textAlign: "left"}}>
                            The fundamental point - it makes sense to undertake welding work only if it is possible to boil the root.

                        </p>
                        <p style={{width: "80%", textAlign: "left"}}>
                            Welding works are carried out semiautomatically. Throughout the process, it is necessary to control that the metal at the welding site does not overheat. Stop to let it cool down.
                        </p>
                    </div>
                    <div style={{width: "100%", textAlign: "left"}}>
                        <h3> Seam processing after welding.  </h3>
                        <p style={{width: "80%", textAlign: "left"}}>
                            The seam after the repair of the frame of trucks must be processed in order to preserve the metal in the conditions of aggressive environmental influences.
                        </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>Clean the seam flush with the main surface. This will not only improve the aesthetics of the frame but also evaluate the quality of welding.
                            </li>
                            <li>Decorate the area. Pre-degrease and prime it.
                            </li>
                        </p>
                    </div>
                </div>

                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <h2>Repair Cost </h2>
                        <p style={{textAlign: "left"}}>
                            The cost of frame repair depends on various factors - the location and size of the damage, and its availability. So, the need to dismantle the body requires additional time and effort and therefore increases the cost of work.
                        </p>
                        <p style={{textAlign: "left"}}>Influence the final figure and overlays or their absence.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Blog6;
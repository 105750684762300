import Navbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
import Breadcrumbs from "../breadcrumbs";
import React from "react";

const Layout = ({children}) => {
    return (
        <>
            <Navbar/>
            <Breadcrumbs />
            <main>{children}</main>
            <Footer/>
        </>
    )
}

export default Layout

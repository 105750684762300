import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import car1 from "../../Assets/Images/service17.jpeg"
import car2 from "../../Assets/Images/rmpaint.webp"
import car3 from "../../Assets/Images/carbonfiber.jpg"
import car4 from "../../Assets/Images/diagnos.jpg"
import car5 from "../../Assets/Images/autoframe.jpg"
import car6 from "../../Assets/Images/Aluminium.jpg"
import car7 from "../../Assets/Images/claimAssistance.jpg"

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./style.scss";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

export default function SwiperGallery() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return (
        <div className="swiper_gallery">
            <div className="filter"></div>
            <div className="swiper-button image-swiper-button-next">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17.0864" cy="17.0864" r="17.0864" fill="white" fillOpacity="0.41"/>
                    <path d="M12.321 7L23 17.2341L12.321 27.0232" stroke="#111010" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="swiper-button image-swiper-button-prev">
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17.0854" cy="17.0865" r="17.0864" transform="rotate(-180 17.0854 17.0865)" fill="white" fillOpacity="0.41"/>
                    <path d="M22.679 27L12 16.7659L22.679 6.97682" stroke="#111010" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled"
                }}
                loop={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                <SwiperSlide>
                    <img src={car1}/>
                    <h5>Auto Body Repairs</h5>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car2} />
                    <h5>Auto Body Paint</h5>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car3} />
                    <h5>Auto Carbon Fiber & Fiberglass Repairs</h5>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car4} />
                    <h5>Auto Diagnostics & Mechanical Work</h5>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car5} />
                    <h5>Auto Frame Work</h5>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car6} />
                    <h5>Car Aluminum Repairs</h5>
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car7} />
                    <h5>Claims Assistance</h5>
                </SwiperSlide>
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={car1} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car2} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car3} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car4} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car5} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car6} />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={car7} />
                </SwiperSlide>

            </Swiper>
        </div>
    );
}

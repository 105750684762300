// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Assets/Fonts/hemi_head/hemi head bd it.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'hemi_head';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
*{
    box-sizing: border-box;
}
a{
    text-decoration: none;
    display: inline-block;
}

html.dontScroll {
    overflow: hidden;
}
.breadcrumbs {
    margin: 80px auto;
    display: flex;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 2;
}

.breadcrumbs a:before{
    content: "/\\00a0";
    padding: 6px;
}
.breadcrumbs  a {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #ddd;
    text-transform: capitalize;
}
.breadcrumbs  a:hover {
    color: #bfbfbf;
}
.crumb a{
    color: #ED1C24;
}
.crumb a:hover{
    color: #ED1C24;
}
@media (max-width: 1200px) {
   .breadcrumbs{
       margin: 10px auto;
       position: static;
   }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,4CAAsD;AAC1D;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;AACA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,0BAA0B;AAC9B;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;GACG;OACI,iBAAiB;OACjB,gBAAgB;GACpB;AACH","sourcesContent":["@font-face {\n    font-family: 'hemi_head';\n    src: url('Assets/Fonts/hemi_head/hemi head bd it.otf');\n}\n*{\n    box-sizing: border-box;\n}\na{\n    text-decoration: none;\n    display: inline-block;\n}\n\nhtml.dontScroll {\n    overflow: hidden;\n}\n.breadcrumbs {\n    margin: 80px auto;\n    display: flex;\n    position: absolute;\n    top: 0;\n    left: 10px;\n    z-index: 2;\n}\n\n.breadcrumbs a:before{\n    content: \"/\\00a0\";\n    padding: 6px;\n}\n.breadcrumbs  a {\n    text-decoration: none;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 19px;\n    color: #ddd;\n    text-transform: capitalize;\n}\n.breadcrumbs  a:hover {\n    color: #bfbfbf;\n}\n.crumb a{\n    color: #ED1C24;\n}\n.crumb a:hover{\n    color: #ED1C24;\n}\n@media (max-width: 1200px) {\n   .breadcrumbs{\n       margin: 10px auto;\n       position: static;\n   }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast--success {
  border: 1px solid #052722 !important;
  border-radius: 22px !important;
  background: #F0F9FA !important;
}

.Toastify__toast-body {
  color: #444C63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
}

.Toastify__toast > button > svg {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ContactUs/Tostify.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,8BAAA;EACA,8BAAA;AACF;;AAGA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,4BAAA;AAAF;;AAGA;EACE,aAAA;AAAF","sourcesContent":[".Toastify__toast--success {\n  border: 1px solid #052722 !important;\n  border-radius: 22px !important;\n  background: #F0F9FA !important;\n}\n\n\n.Toastify__toast-body {\n  color: #444C63;\n  font-size: 16px;\n  padding-left: 20px;\n  line-height: 20px;\n  padding: 0px;\n  padding-top: 25px;\n  width: 100%;\n  font-weight: 400;\n  margin-left: 25px !important;\n}\n\n.Toastify__toast > button > svg {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import Layout from "../../Layouts/Layouts";
import styles from "./NotFound.module.scss"
import {NavLink} from "react-router-dom";

const NotFound = () => {
    return (
            <div className={styles.notFound}>
                <h1>:(</h1> <br/>
                <h2>A <span>404</span> error occurred, Page not found, check the URL and try again.</h2><br/><br/>
                <h3><NavLink to={"/home"} exact >Return to home |</NavLink></h3>
            </div>
    );
};

export default NotFound;
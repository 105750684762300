// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/Images/servicebg1.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Services_serviceBlock1__Cd-pY {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.Services_serviceBlock1__Cd-pY .Services_title__wMfEe {
  text-align: center;
}
.Services_serviceBlock1__Cd-pY .Services_title__wMfEe h1 {
  font-family: "hemi_head";
  font-style: italic;
  font-weight: 400;
  padding-top: 50px;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
}
@media (max-width: 1200px) {
  .Services_serviceBlock1__Cd-pY .Services_title__wMfEe h1 {
    padding-top: 0;
  }
}
.Services_serviceBlock1__Cd-pY .Services_title__wMfEe h1:before {
  content: "";
  width: 80%;
  height: 1px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ED1C24;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Services/Services.module.scss"],"names":[],"mappings":"AAAA;EACE,mDAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;AACF;AACE;EACE,kBAAA;AACJ;AACI;EACE,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,qBAAA;EACA,mBAAA;AACN;AAAM;EAZF;IAaI,cAAA;EAGN;AACF;AADM;EACE,WAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,2BAAA;EACA,yBAAA;AAGR","sourcesContent":[".serviceBlock1 {\n  background: url(\"../../Assets/Images/servicebg1.jpg\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n\n  .title {\n    text-align: center;\n\n    h1 {\n      font-family: \"hemi_head\";\n      font-style: italic;\n      font-weight: 400;\n      padding-top: 50px;\n      font-size: 40px;\n      text-align: center;\n      letter-spacing: 0.01em;\n      color: #FFFFFF;\n      position: relative;\n      display: inline-block;\n      margin-bottom: 50px;\n      @media (max-width: 1200px) {\n        padding-top: 0\n      }\n\n      &:before {\n        content: \"\";\n        width: 80%;\n        height: 1px;\n        position: absolute;\n        bottom: -10px;\n        left: 50%;\n        transform: translateX(-50%);\n        background-color: #ED1C24;\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceBlock1": `Services_serviceBlock1__Cd-pY`,
	"title": `Services_title__wMfEe`
};
export default ___CSS_LOADER_EXPORT___;

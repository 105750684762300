import React, {useEffect} from 'react';
import classes from "./Certificates.module.scss"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import img1 from "../../Assets/Images/certificates/alfa-romeo-1.jpg"
import img2 from "../../Assets/Images/certificates/nissan.jpg"
import img4 from "../../Assets/Images/certificates/կիա.jpg"
import img5 from "../../Assets/Images/certificates/fca.jpg"
import img6 from "../../Assets/Images/certificates/certified.jpg"
import img7 from "../../Assets/Images/certificates/hyundai.jpg"
import img8 from "../../Assets/Images/certificates/infiniti.jpg"
import img9 from "../../Assets/Images/certificates/mercedes.jpg"
import img10 from "../../Assets/Images/certificates/gold-class.jpg"
import img11 from "../../Assets/Images/certificates/aluminium.jpg"

const Certificates = () => {
    // useEffect(() => {
    //     let descMeta = document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'If you want to have your vehicle repaired by certified technicians, choose LA West. Our technicians are skilled and knowledgeable about all makes and models.')
    // }, [])
    return (
        <Layout>
            <Helmet>
                <title>Our Certifications | LA West Collision Center</title>
                <meta name="description"
                      content="If you want to have your vehicle repaired by certified technicians, choose LA West. Our technicians are skilled and knowledgeable about all makes and models.  "/>
                <link rel="canonical" href={"https://www.lawestcollision.com/certificates"} />
            </Helmet>
            <div className={classes.certificatesHeader}>
                <video className={classes.videoTag} loop autoPlay={true} muted={true} playsInline={true}>
                    <source src="certificates.mp4" type='video/mp4'/>
                </video>
                <div className={classes.layer}></div>
                <p>LA West Collision Center: Certified for Mercedes-Benz, Infiniti, Nissan, Kia, Hyundai, Alfa Romeo,
                    Chrysler, Dodge, Fiat, Jeep, Ram, Mopar. We hold the prestigious I-CAR Gold Class Certificate since
                    2022, ensuring top-quality repairs and expert service you can trust, all backed by our commitment to
                    customer satisfaction.</p>
            </div>
            <h1 className={classes.headingCertificate}>LA West's Certifications</h1>
            <div className={classes.certificatesBox}>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img10})`}}></div>
                    <img src={img10} alt=""/>
                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img9})`}}></div>
                    <img src={img9} alt=""/>
                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img8})`}}></div>
                    <img src={img8} alt=""/>
                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img2})`}}></div>
                    <img src={img2} alt=""/>
                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img4})`}}></div>
                    <img src={img4} alt=""/>
                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img7})`}}></div>
                    <img src={img7} alt=""/>
                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img1})`}}></div>
                    <img src={img1} alt=""/>
                </div>

                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img5})`}}></div>
                    <img src={img5} alt=""/>

                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img6})`}}></div>
                    <img src={img6} alt=""/>
                </div>
                <div className={classes.certificatesItem}>
                    <div className={classes.certificatesBackground} style={{backgroundImage: `url(${img11})`}}></div>
                    <img src={img11} alt=""/>
                </div>
            </div>
        </Layout>
    );
};

export default Certificates;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_videoTag__fVOlC {
  width: 100%;
}

.Header_Box__kADWK {
  position: absolute;
  bottom: 15%;
  left: 5%;
  max-width: 700px;
}
@media (max-width: 780px) {
  .Header_Box__kADWK {
    max-width: 500px;
  }
}
@media (max-width: 980px) {
  .Header_Box__kADWK {
    width: 95%;
    left: 0%;
    top: 25%;
  }
}
.Header_Box__kADWK h1, .Header_Box__kADWK h2 {
  color: #ED1C24;
  font-weight: 700;
  font-size: 48px;
  position: relative;
}
@media (max-width: 780px) {
  .Header_Box__kADWK h1, .Header_Box__kADWK h2 {
    font-size: 38px;
    display: none;
  }
}
@media (max-width: 500px) {
  .Header_Box__kADWK h1, .Header_Box__kADWK h2 {
    font-size: 24px;
  }
}
.Header_Box__kADWK h6 {
  color: #FFFFFF;
}
@media (max-width: 500px) {
  .Header_Box__kADWK h6 {
    display: none;
  }
}
.Header_Box__kADWK .Header_animation_txt__0KsiL {
  animation: Header_movingTopToBottom__Upd0s 1s;
  background: linear-gradient(180deg, #FFFFFF -3.45%, #FFFFFF 66.44%, rgba(228, 131, 135, 0) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
@keyframes Header_movingTopToBottom__Upd0s {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,gBAAA;AACF;AAAE;EALF;IAMI,gBAAA;EAGF;AACF;AAFE;EARF;IASI,UAAA;IACA,QAAA;IACA,QAAA;EAKF;AACF;AAHE;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;AAKJ;AAJI;EALF;IAMI,eAAA;IACA,aAAA;EAOJ;AACF;AANI;EATF;IAUI,eAAA;EASJ;AACF;AANE;EACE,cAAA;AAQJ;AAPI;EAFF;IAGI,aAAA;EAUJ;AACF;AAPE;EACE,6CAAA;EACA,gGAAA;EACA,qBAAA;EACA,oCAAA;EACA,6BAAA;AASJ;AAPI;EACE;IACE,UAAA;IACA,4BAAA;EASN;EANI;IACE,UAAA;IACA,yBAAA;EAQN;AACF","sourcesContent":[".videoTag {\n  width: 100%;\n}\n\n.Box {\n  position: absolute;\n  bottom: 15%;\n  left: 5%;\n  max-width: 700px;\n  @media (max-width: 780px) {\n    max-width: 500px;\n  }\n  @media (max-width: 980px) {\n    width: 95%;\n    left: 0%;\n    top: 25%;\n  }\n\n  h1,h2 {\n    color: #ED1C24;\n    font-weight: 700;\n    font-size: 48px;\n    position: relative;\n    @media (max-width: 780px) {\n      font-size: 38px;\n      display: none;\n    }\n    @media (max-width: 500px) {\n      font-size: 24px;\n    }\n\n  }\n  h6{\n    color: #FFFFFF;\n    @media (max-width: 500px) {\n      display: none;\n    }\n  }\n\n  .animation_txt {\n    animation: movingTopToBottom 1s;\n    background: linear-gradient(180deg, #FFFFFF -3.45%, #FFFFFF 66.44%, rgba(228, 131, 135, 0) 100%);\n    background-clip: text;\n    -webkit-text-fill-color: transparent;\n    -webkit-background-clip: text;\n\n    @keyframes movingTopToBottom {\n      0% {\n        opacity: 0;\n        transform: translateY(-100%);\n      }\n\n      100% {\n        opacity: 1;\n        transform: translateY(-0%);\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoTag": `Header_videoTag__fVOlC`,
	"Box": `Header_Box__kADWK`,
	"animation_txt": `Header_animation_txt__0KsiL`,
	"movingTopToBottom": `Header_movingTopToBottom__Upd0s`
};
export default ___CSS_LOADER_EXPORT___;

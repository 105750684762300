const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id,path) =>
    `/${path}/${id}.jpeg`;

const unsplashPhotos = [
    { id: "gallery1",path:"1", width: 1300, height: 500 },
    { id: "gallery2",path:"1", width: 1300, height: 500 },
    { id: "gallery3",path:"1", width: 1300, height: 500 },
    { id: "gallery4",path:"1", width: 1300, height: 500 },
    { id: "gallery5",path:"1", width: 1300, height: 500 },
    { id: "gallery6",path:"1", width: 1300, height: 500 },
    { id: "gallery7",path:"1", width: 1300, height: 500 },
    { id: "gallery8",path:"1", width: 1300, height: 500 },
    { id: "gallery9",path:"1", width: 1300, height: 500 },
    { id: "gallery10",path:"1", width: 1300, height: 500 },
    { id: "gallery11",path:"1", width: 1300, height: 500 },
    { id: "gallery12",path:"1", width: 1300, height: 500 },
    { id: "gallery13",path:"1", width: 1300, height: 500 },
    { id: "gallery14",path:"1", width: 1300, height: 500 },
    { id: "gallery15",path:"1", width: 1300, height: 500 },
    { id: "gallery17",path:"1", width: 1300, height: 500 },
    { id: "gallery18",path:"1", width: 1300, height: 500 },
    { id: "gallery19",path:"1", width: 1300, height: 500 },
    { id: "gallery20",path:"1", width: 1300, height: 500 },
    { id: "gallery21",path:"1", width: 1300, height: 500 },
    { id: "gallery22",path:"1", width: 1300, height: 500 },
];

const photos = unsplashPhotos.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: unsplashLink(photo.id,photo.path),
        width,
        height,
        images:
        // breakpoints.map((breakpoint) => {
        // const height = Math.round((photo.height / photo.width) * breakpoint);
        // return {
            {
                src: unsplashLink(photo.id, photo.path, height),
                width: photo.width,
                height,
            },
        // };
        // }),
    };
});

export default photos;
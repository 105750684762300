// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider .swiper {
  width: 80%;
  height: 100%;
}
.slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/Components/Slider/style.scss"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,YAAA;AAAJ;AAGE;EACE,kBAAA;EACA,eAAA;EAEA,iCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,cAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAHJ","sourcesContent":[".slider{\n  .swiper {\n    width: 80%;\n    height: 100%;\n  }\n\n  .swiper-slide {\n    text-align: center;\n    font-size: 18px;\n\n    /* Center slide text vertically */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .swiper-slide img {\n    display: block;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, {useEffect} from 'react';
import ServiceItem from "./ServiceItem";
import img1 from "../../Assets/Images/claimAssistance.jpg"
import img2 from "../../Assets/Images/windshield.jpg"
import img3 from "../../Assets/Images/shuttle.jpg"
import img4 from "../../Assets/Images/insurance.jpg"
import img5 from "../../Assets/Images/carbonfiber.jpg"
import img6 from "../../Assets/Images/freeestimate.jpg"
import img7 from "../../Assets/Images/mechanicalrepair.jpg"
import img8 from "../../Assets/Images/diagnostics.jpeg"
import img9 from "../../Assets/Images/Advancedpaintless.jpg"
import img10 from "../../Assets/Images/caroliner.jpg"
import img11 from "../../Assets/Images/rmpaint.webp"
import img12 from "../../Assets/Images/Aluminium.jpg"
import img13 from "../../Assets/Images/towingservice.jpg"
import img14 from "../../Assets/Images/oemreplace.jpg"
import img15 from "../../Assets/Images/rentals.jpg"
import img16 from "../../Assets/Images/wheelrepair.jpg"
import classes from "./Services.module.scss"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";

const Services = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'At LA West, our experienced technicians are dedicated to providing exceptional service and delivering the highest quality workmanship. Contact us today!')
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Services | LA West</title>
                <meta name="description" content="At LA West, our experienced technicians are dedicated to providing exceptional service and delivering the highest quality workmanship. Contact us today!" />
                <link rel="canonical" href={"https://www.lawestcollision.com/services"} />
            </Helmet>
            <div className={classes.serviceBlock1}>
                <div className={classes.title}><h1>Our Services</h1></div>
                <ServiceItem  img={img1} text="Our Team of Professionals will guide you through the process of filing a claim." title="Claim Assistance "/>
                <ServiceItem  img={img2} text="Industries best servicemen insuring your vehicle is properly repaired and recalibrated." title="Windsheild Replacement Services"/>
                <ServiceItem  img={img3} text="We offer a shuttle service to all our clients that have brought in their vehicle and do not have a ride" title="Shuttle Service "/>
                <ServiceItem  img={img4} text="Highly trained group of individuals that carry our reputation in the insurance industry to make the repair process as swift and professional as possible" title="Insurance Repairs"/>
                <ServiceItem img={img5} text="Custom curation for the repairs of special material vehicles with the industries latest equipment" title="Carbon Fiber & Fiberglass Repairs " />
                <ServiceItem img={img6} text="Our team of graduated appraisers pay attention to the slightest of imperfections to insure the quality of your vehicles repair completion." title="Free Estimate" />
                <ServiceItem img={img7} text="I-Car Certfied technicians completing mechanical repairs with exceding the industries standards" title="Mechanical Repairs" />
                <ServiceItem img={img8} text="Trained professionals using the latest Autel System to diagnose, calibrate, and program the vehicles safety protocalls. (ADAS, ABS Restraint Systems ETC.)" title="Digital Diagnostics" />
                <ServiceItem img={img9} text="Insurring the vehicles original paint job, and working around it to keep maximum quality repairs. " title="Advanced Paintless Dent Repairs" />
                <ServiceItem img={img10} text="3D Scanning and Laser measurements following the training and requirements from the CAR-O-LINER manufacturer" title="CAR-O-LINER" />
                <ServiceItem img={img11} text="Using the industries most harmless and toxin free water based paint to maximize both the safety of our valued clients and restoring the dealer showroom shine" title="RM Paint & Refinishing" />
                <ServiceItem img={img12} text="Special designated room for aluminum repairs to eliminate the factor of outside particles interfeering with the repairs and materials being used to complete the highest repair quality." title="Aluminum Repairs" />
                <ServiceItem img={img16} text="Minor Dented & Scratched wheels are repairable by our team of experienced technicians. All wheels are applicable (Powder Coat, Chrome, Allow, Forged, 3 Piece ETC.)" title="Alloy Wheel Repair" />
                <ServiceItem img={img13} text="No matter the distance or condition of the vehicle, we will get it towed to our facility hastle free and complimentary for our new and loyal customers" title="24/7 Towing Services" />
                <ServiceItem img={img14} text="All essential parts required for completing repairs in handled by our parts department. We double check each and every part directly with the vehicle manufacturer" title="OEM Replacemt Parts" />
                <ServiceItem img={img15} text="We work with all rental to secure a new and clean vehicle for our customer " title="Vehicle Rentals" />
            </div>
        </Layout>
    );
};

export default Services;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sitemap_container__O1YwZ {
  min-height: 100vh;
  margin-top: 80px;
  text-align: center;
}
.sitemap_container__O1YwZ h1, .sitemap_container__O1YwZ h2 {
  color: white;
}
.sitemap_container__O1YwZ .sitemap_box__-7w0c {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  margin: auto;
}
.sitemap_container__O1YwZ .sitemap_box__-7w0c div {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sitemap_container__O1YwZ .sitemap_box__-7w0c a {
  color: #DD0000;
  text-decoration: underline;
  margin-bottom: 20px;
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Sitemap/sitemap.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;AACF;AAAE;EACE,YAAA;AAEJ;AAAE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,UAAA;EACA,YAAA;AAEJ;AADI;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAGN;AADI;EACE,cAAA;EACA,0BAAA;EACA,mBAAA;EACA,eAAA;AAGN","sourcesContent":[".container{\n  min-height: 100vh;\n  margin-top: 80px;\n  text-align: center;\n  h1,h2{\n    color: white;\n  }\n  .box{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 80%;\n    margin: auto;\n    div{\n      margin-left: 20px;\n      display: flex;\n      flex-direction: column;\n      align-items: flex-start;\n    }\n    a{\n      color: #DD0000;\n      text-decoration: underline;\n      margin-bottom: 20px;\n      font-size: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sitemap_container__O1YwZ`,
	"box": `sitemap_box__-7w0c`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import classes from "./Blog.module.scss"
import img1 from "../../Assets/Images/blog1.webp"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import img from "../../Assets/Images/service17.jpeg";
import img2 from "../../Assets/Images/rmpaint.webp";
import img3 from "../../Assets/Images/windshield.jpg";
import img4 from "../../Assets/Images/diagnostics.jpeg";
import img5 from "../../Assets/Images/blog55.jpg";
import img6 from "../../Assets/Images/blog44.jpg";
import img7 from "../../Assets/Images/insurance.jpg";
import img8 from "../../Assets/Images/diagnos.jpg";
import BlogItem from "./BlogItem";

const Blog = () => {

    return (
        <Layout>
            <Helmet>
                <title>Blog | LA West</title>
                <meta name="description" content={"Stay up-to-date with the latest news and insights from LA West's blog. Learn about valuable car maintenance tips, industry trends, and customer stories. "} />
                <link rel="canonical" href={"https://www.lawestcollision.com/blog"} />
            </Helmet>
            <div className={classes.blog}>
                <img src={img1} alt=""/>
                <div className={classes.text}>
                    <h1>Our Blog</h1>
                </div>

            </div>
           <BlogItem link={"https://www.lawestcollision.com/tips-for-finding-reliable-mechanic"}
           title={"How To Find An Auto Mechanic You Can Trust"}
           desccription={"An auto mechanic is a generalized name for specialists who, to one degree or another, are involved in the repair and maintenance of a car. And finding a mechanic is one of the hardest things for car owners. Everyone needs that reliable auto repair should be done by quality mechanics. And this is true because we want our mechanics to be professional, understanding, honest, and last but not least will be near us. Before reliable auto repair services from an auto mechanic, you should decide what type of assistance your vehicle needs.\n"}
           image={img}
           />
            <BlogItem
            image={img2}
            link={"https://www.lawestcollision.com/signs-of-bad-body-shop-paint-job"}
            title={"Signs Your Auto Body Paint Shop Did a Bad Job: How to Tell if Paint is Bad "}
            desccription={"Painting a car is a delicate matter. Every car painter has so many ways to ruin all his work. Auto paint defects examples are so many and everyone makes mistakes, both beginners and professionals - the main thing is to draw the right conclusions from these mistakes and not repeat them again. However, very often it is quite difficult to find the cause of a painting error. The varnishing process alone contains more than a dozen technological operations, and it is essential to follow all technical procedures."}
            />
            <BlogItem
                image={img3}
                link={"https://www.lawestcollision.com/what-to-do-after-car-accident"}
                title={"What to Do After a Car Accident: A Step-by-Step Guide"}
                desccription={"Driving a car is not only a pleasant experience. There are a lot of traffic accidents on the roads every year, and most people don't even know what to do after a car accident. If you actively use the car, you will have an accident sooner or later. Accuracy and good driving skills reduce the risk but only partially eliminate it. And it is better to know what to do after a car accident to avoid mistakes."}
            />
            <BlogItem
                image={img4}
                link={"https://www.lawestcollision.com/why-is-my-car-vibrating"}
                title={"Why is My Car Shaking? Most Common Reasons"}
                desccription={"Don't let a shaky car ruin your day! Discover the most common reasons why your car may be shaking and find expert solutions at LA West."}
            />
            <BlogItem
                image={img5}
                link={"https://www.lawestcollision.com/car-maintenance-and-care-tips"}
                title={"Car Care Tips: How to Maintain and Care for Your Car"}
                desccription={"Discover car care tips to keep your vehicle running smoothly. Learn how to maintain and care for your car with expert advice and helpful guides. Keep your car!"}
            />
            <BlogItem
                image={img6}
                link={"https://www.lawestcollision.com/repair-or-to-replace-frame-rails"}
                title={"Frame Rail: Repair vs. Replacement for Vehicle Safety"}
                desccription={"Ensure your vehicle's safety with frame rail repair or replacement. LA West blog provides expert insights on when to repair and when to replace frame rails."}
            />
            <BlogItem
                image={img7}
                link={"https://www.lawestcollision.com/car-frame-damage"}
                title={"Car Frame Damage: What Is It & Can Frame Damage be Fixed?"}
                desccription={"What is frame damage on a car? Our experts explain what car frame damage is and how it can be fixed. Get informed and make the right decisions for your vehicle."}
            />
            <BlogItem
                image={img8}
                link={"https://www.lawestcollision.com/tips-for-driving-long-distances"}
                title={"Things You Need to Check on Your Car Before a Road Trip"}
                desccription={"Plan your long distance road trip with confidence. Before hitting the road, check out our guide on what to inspect and prepare for a worry-free journey."}
            />
        </Layout>
    );
};

export default Blog;
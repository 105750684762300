import {useLocation, Link, useNavigate} from "react-router-dom"
import "./App.css"
import {Typography} from "@mui/material";

export default function Breadcrumbs() {
    const location = useLocation()
   const navigate = useNavigate()
    let currentLink = ''

    const crumbs = location.pathname.split('/')
        .filter(crumb => crumb === '/' || crumb !=='')
        .map(crumb => {
            currentLink += `/${crumb}`
            return (
                <div className="crumb" key={crumb}>
                    {currentLink === '/auto-body-repair' || currentLink === '/car-painting' || currentLink === '/fiberglass-repair' || currentLink === "/car-mechanic" || currentLink === "/vehicle-frame-repair"  ? <><Link to={'/services'}>services</Link> / <Link to={currentLink}>{crumb}</Link> </>: currentLink === '/signs-of-bad-body-shop-paint-job' || currentLink === '/tips-for-finding-reliable-mechanic' || currentLink === '/what-to-do-after-car-accident' || currentLink === '/why-is-my-car-vibrating' || currentLink === '/car-maintenance-and-care-tips' || currentLink === '/car-frame-damage' || currentLink ==='/tips-for-driving-long-distances' ? <><Link to={'/blog'}>blog</Link> <Link to={currentLink}>{crumb}</Link></>  : <Link to={currentLink}>{crumb}</Link> }
                </div>
            )
        })
    return (
        <div className="breadcrumbs">
            {currentLink.length > 0 ? (
                <Link to={'/'}>Home</Link>
            ) : (
                // <Typography style={{color:"#ED1C24"}} > / Home </Typography>
                <span></span>
            )}
            {crumbs}
        </div>
    )
}
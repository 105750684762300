import './App.css';
import {BrowserRouter, useLocation} from "react-router-dom";
import Routers from "./route";
import Breadcrumbs from "./breadcrumbs";
import {Helmet} from "react-helmet";
import {useEffect} from "react";
import {useMeta} from "./MetaContext";

function App() {
    // const { meta, updateMeta } = useMeta();
    //
    // useEffect(() => {
    //     // Update meta tags when component mounts or as needed
    //     updateMeta({
    //         title: 'Auto Body Shop | Collision Center in Los Angeles | LA West',
    //         description: 'LA West  is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details.',
    //         keywords: 'auto body shop, collision center, car body shop, auto body shop los angeles, collision repair'
    //     });
    // }, []);

    return (
        <BrowserRouter>
            {/*<Helmet>*/}
            {/*    <title>{meta.title}</title>*/}
            {/*    <meta name="description" content={meta.description} />*/}
            {/*    <meta name="keywords" content={meta.keywords} />*/}
            {/*    <link rel="canonical" href={"https://www.lawestcollision.com"} />*/}
            {/*</Helmet>*/}
            <Routers/>
        </BrowserRouter>
    );
}

export default App;
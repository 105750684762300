import React, {useEffect, useState} from 'react';
import styles from "./Careers.module.scss"
import img from "../../Assets/Images/careers.webp"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import styles1 from "../Blog/Blog.module.scss"
import {toast, ToastContainer} from "react-toastify";
import CareersItem from "./CareersItem";
import {injectStyle} from "react-toastify/inject-style";

if (typeof window !== "undefined") {
    injectStyle();
}
const Careers = () => {

    return (
        <Layout>
            <Helmet>
                <title>Careers | LA West</title>
                <meta name="description"
                      content="Explore our career opportunities, competitive compensation packages, and comprehensive benefits package today. Grow your skills and advance your career with us."/>
                <link rel="canonical" href={"https://www.lawestcollision.com/careers"}/>
            </Helmet>
            <div className={styles.careers}>
                <div className={styles.title}><h1>Careers</h1>
                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur assumenda doloremque, eligendi</p>*/}
                </div>
                {/*<img src={img} alt=""/>*/}
            </div>
            <div className={styles.itemBox}>
                <CareersItem title={'Auto Body Technician'} text={'An auto body technician is responsible for repairing and restoring vehicles to their pre-accident condition. They possess the skills to assess damages, perform bodywork, apply paint, and ensure the structural integrity and aesthetics of vehicles are meticulously maintained. Having an I-CAR, ASE or any manufacturer Certification is a plus.'}/>
                <CareersItem title={'Auto Body Technician Helper'} text={'An auto body technician helper provides essential support to auto body technicians in their day-to-day tasks. They assist with preparing vehicles for repairs, handling tools and equipment, and ensuring a clean and organized work environment, contributing to the smooth and efficient operation of the auto body repair process.'}/>
                <CareersItem title={'Auto Painter'} text={'An auto painter is responsible for applying paint and finishing touches to vehicles, ensuring a smooth and flawless appearance. They possess expertise in color matching, surface preparation, and using specialized equipment to achieve high-quality paint finishes, enhancing the overall aesthetics of the vehicle. Having an I-CAR, ASE or any manufacturer Certification is a plus.'} />
                <CareersItem title={'Auto Painter Helper'} text={'An auto painter helper provides crucial assistance to auto painters in their day-to-day tasks. They assist with preparing surfaces, sanding, masking, and cleaning equipment, contributing to the efficient workflow and ensuring a smooth and successful paint application process.'} />
                <CareersItem title={'Auto Polishing & Detail '} text={'An auto polishing and detail specialist is responsible for meticulously cleaning, polishing, and restoring the exterior and interior of vehicles to a pristine condition. They possess expertise in using various techniques, products, and tools to enhance the shine, remove imperfections, and maintain the overall aesthetic appeal of the vehicle.'} />
            </div>
            <ToastContainer/>
        </Layout>

    );
};

export default Careers;
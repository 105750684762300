import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "./index.scss"

const Item = ({question,answer}) => {
    return (
        <Accordion className='accordion'>
            <AccordionSummary
                className="summary"
                expandIcon={<ExpandMoreIcon className="icon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h4>{question}</h4>
            </AccordionSummary>
            <AccordionDetails className="details" >
                <p>{answer}</p>
            </AccordionDetails>
        </Accordion>
    );
};

export default Item;
import React, {useEffect} from 'react';
import Layout from "../../Layouts/Layouts";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/carbonfiber.jpg"
import {Helmet} from "react-helmet";
import {useLocation, useNavigate} from "react-router-dom";

const FiberglassRepair = () => {
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'If you need auto frame restoration in Los Angeles, we can take care of your vehicle. Contact us for car frame repair services, let us restore your car.')
    //     let themeMeta=document.querySelector("meta[name='keywords']")
    //     themeMeta.setAttribute("content", "fiberglass repair, Fiberglass Repair Shop, fiberglass car repair")
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Fiberglass Repair for Cars | Fiberglass Repair Shop | LA West</title>
                <meta name="description"
                      content="If you need auto frame restoration in Los Angeles, we can take care of your vehicle. Contact us for car frame repair services, let us restore your car. "/>
                <meta name="keywords" content="fiberglass repair, Fiberglass Repair Shop, fiberglass car repair"/>
                <script type="application/ld+json">
                    {`
                    {
  "@context": "https://schema.org",
  "@type": "Service",
  "provider": {
    "@type": "AutoBodyShop",
    "name": "LA West Collision Center",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "6827 Troost Ave North Hollywood, CA 91605",
      "addressLocality": "Los Angeles",
      "postalCode": "91605",
      "addressCountry": "US"
    },
    "telephone": "(818) 821-6033",
    "url": "https://www.lawestcollision.com",
    "logo": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg",
    "description": "LA West is the premier Auto Body Shop in Los Angeles. We are dedicated to top quality collision repair and customer care! Get in touch with us for details.
"
  },
  "serviceType": "Fiberglass Repair",
  "offers": {
    "@type": "Offer",
    "priceCurrency": "USD",
    "url": "https://www.lawestcollision.com/fiberglass-repair"
  },
  "areaServed": {
    "@type": "City",
    "name": "Los Angeles"
  },
  "description": "Fiberglass Repair service specializes in mending and restoring fiberglass structures, often found in vehicles, boats, and various equipment. Technicians skillfully address cracks, holes, and wear, ensuring the restored surface is both strong and visually consistent with its original state."
}

                   `}
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/fiberglass-repair"} />
            </Helmet>
            <div className={styles.container}>
                <h1>Fiberglass Repair</h1>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <p>
                            In the production of the bodies of some cars, fiberglass was used instead of steel. Although
                            this is a rather fragile material, it has some advantages over steel.
                            The fiberglass car repair and the material itself have a number of undeniable advantages
                            compared to steel:

                        </p>
                        <p style={{textAlign:"left"}}>
                            <li style={{marginLeft:"20px"}}>It is easy to repair</li>
                            <li style={{marginLeft:"20px"}}>The material is resistant to moisture</li>
                            <li style={{marginLeft:"20px"}}>Possesses low thermal conductivity</li>
                            <li style={{marginLeft:"20px"}}>Easy to use, and easy to install</li>
                            <li style={{marginLeft:"20px"}}>Fiberglass repair parts are known for their extended durability, providing a long-lasting solution for your automotive needs. </li>
                            <li style={{marginLeft:"20px"}}>Opting for fiberglass body car repair, it offers a cost-effective solution for car repairs without compromising on quality</li>
                            <li style={{marginLeft:"20px"}}>It is not affected by rust</li>
                        </p>
                        <p>The main equipment that will be required to perform fiberglass repair, in addition to the
                            basic tools of the workshop in the <a href="https://lawestcollision.com/" target={"_blank"} style={{color:"#ededed"}}><b>collision center</b></a> , includes a paintbrush, rubber gloves, a
                            resin mixing container, a mixing paddle, and a large number of papers. But fiberglass repair
                            has its own features which should follow any professional <a href="https://lawestcollision.com/car-mechanic" style={{color:"#ededed"}} target={"_blank"}><b>auto mechanic</b></a>.
                        </p>
                    </div>
                </div>
                <div className={styles.itemBlock}>
                    <div>
                        <h2>Brief Information About Fiberglass </h2>
                        <div className={styles.text1}>
                            <p>This material's most correct definition is plastic, reinforced with glass. Its difference
                                from ordinary plastic is that it is not affected by heat, therefore it does not soften.
                                Having a small mass, it has greater strength than some steel. </p>
                        </div>
                        <div className={styles.text2}>
                            <p>The fibers of the material, which are thicker, are formed from thousands of
                                interconnected threads, having a microscopic size. They are barely visible to the eye
                                and are the basis. The color of the base looks white, but in reality, this color is the
                                substance that binds and holds the threads together so that they take the form of a
                                sheet as a result. Understanding the features of this unifying substance will be
                                necessary when impregnating the base with resin.
                            </p>
                        </div>
                        <div className={styles.text1}>
                            <p>Fiberglass has many useful properties. So, it is easy to use for repairs, you can repair
                                it in any fiberglass repair shop. It can withstand a wide temperature range from -76 to
                                +176 degrees Fahrenheit, this material is resistant to adverse weather conditions. At
                                the same time, fiberglass is strong and lightweight, which makes it an ideal material
                                for improving the design of cars.
                            </p>
                        </div>
                    </div>
                </div>
                {/*<div className={styles.itemBlock}>*/}
                {/*    <div>*/}
                {/*        <h2> Major Repair of The Body From Fiberglass </h2>*/}
                {/*        <div className={styles.text1}>*/}
                {/*            <p>*/}
                {/*                The differences between major repairs and ordinary ones differ only in scale, but this*/}
                {/*                does not affect the degree of complexity. If the replacement of the fiberglass part is*/}
                {/*                not required, then, as with the repair of steel panels, the damage is eliminated by*/}
                {/*                applying a repair patch. The main difference is that when repairing fiberglass panels,*/}
                {/*                there is no need to adjust the patches exactly to size, as with steel patches.*/}

                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div className={styles.text2}>*/}
                {/*            <p>*/}
                {/*                Damage to the fiberglass body in a collision with an obstacle resembles an eggshell. The*/}
                {/*                size and shape of the fragments do not change. Therefore, it is possible to fold the*/}
                {/*                fragments of the body again, successively fastening them one by one.*/}

                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div className={styles.text1}>*/}
                {/*            <p>In the place where the patch is applied, with the help of aluminum plates, fastened in*/}
                {/*                several places along the perimeter of the damage from the outside, the repaired panel is*/}
                {/*                held. To fix the plates, you need to drill holes in them. It is advisable to do this*/}
                {/*                before the sheet is cut into platinum, it is safer and easier. Holes are drilled in*/}
                {/*                fiberglass parts. Using self-tapping screws, the plates are attached to the part.*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </Layout>
    );
};

export default FiberglassRepair;
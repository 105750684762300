// MetaContext.js
import React, { createContext, useContext, useState } from 'react';

const MetaContext = createContext();

export const MetaProvider = ({ children }) => {
    const [meta, setMeta] = useState({
        title: '',
        description: '',
        keywords: '',
        // Add more meta tags as needed
    });
    const [change,setChange] = useState(false)
    const updateMeta = (newMeta) => {
        setMeta(newMeta);
    };

    return (
        <MetaContext.Provider value={{ meta, updateMeta, change, setChange }}>
            {children}
        </MetaContext.Provider>
    );
};

export const useMeta = () => {
    return useContext(MetaContext);
};

import React from 'react';
import Layout from "../../Layouts/Layouts";
import {NavLink} from "react-router-dom";
import classes from "./sitemap.module.scss";
import {Helmet} from "react-helmet";

const Sitemap = () => {
    return (
        <Layout>
            <Helmet>
                <title>Sitemap | LA West</title>
                <meta name="description" content={"Discover the sitemap for LA West. Navigate through our website effortlessly and explore all the exciting information, services, and resources we have to offer."} />
                <link rel="canonical" href={"https://www.lawestcollision.com/sitemap"} />
            </Helmet>
            <div className={classes.container}>
          <h1>Sitemap</h1>
            <h2>Pages</h2>
            <div className={classes.box}>
                <NavLink to='/' exact>Home</NavLink>
                <NavLink to='/services' exact>Services</NavLink>
                <div>
                    <NavLink to='/auto-body-repair' exact>Auto Body Repair</NavLink>
                    <NavLink to='/car-painting' exact>Car Painting</NavLink>
                    <NavLink to='/fiberglass-repair' exact>Fiberglass Repair</NavLink>
                    <NavLink to='/car-mechanic' exact>Auto Mechanic</NavLink>
                    <NavLink to='/vehicle-frame-repair' exact>Frame Straightening</NavLink>
                    <NavLink to='/services' exact>Other Services</NavLink>
                </div>
                <NavLink to='/gallery' exact>Gallery</NavLink>
                <NavLink to='/partners' exact>Partners</NavLink>
                <NavLink to='/certificates' exact>Certifications</NavLink>
                <NavLink to='/certificates/mercedes-collision-center' exact>Mercedes Benz Collision Center</NavLink>
                <NavLink to='/careers' exact>Careers</NavLink>
                <NavLink to='/blog' exact>Blog</NavLink>
                <NavLink to='/about' exact>About Us</NavLink>
                <NavLink to='/contact' exact>Contact Us</NavLink>
                <NavLink to='/faq' exact>FAQ</NavLink>
                <NavLink to='/privacy' exact>Privacy Policy</NavLink>
                <NavLink to='/term' exact>Terms of Use</NavLink>
                <NavLink to='/sitemap' exact>Sitemap</NavLink>
            </div>
            </div>
        </Layout>
    );
};

export default Sitemap;
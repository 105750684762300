import React from 'react';
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
import slider1 from "../../Assets/Images/slider1.png"
import slider2 from "../../Assets/Images/car1.webp"

// Import Swiper styles
import "swiper/css";

import "./style.scss";
import {Autoplay} from "swiper";

const Slider = () => {
    return (
        <div className="slider">
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                speed={1200}
                loop={true}
                modules={[Autoplay]}
                className="mySwiper">
                <SwiperSlide><img src={slider1} alt="car"/></SwiperSlide>
                <SwiperSlide><img src={slider2} alt="car"/></SwiperSlide>
                <SwiperSlide><img src={slider1} alt="car"/></SwiperSlide>
                <SwiperSlide><img src={slider2} alt="car"/></SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Slider;
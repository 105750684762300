// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Insurance {
  height: 250px;
}
@media (max-width: 980px) {
  .Insurance {
    height: 150px;
  }
}
.Insurance .swiper {
  width: 70%;
  height: 100%;
}
.Insurance .swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px !important;
}
@media (max-width: 980px) {
  .Insurance .swiper-slide {
    width: 120px !important;
  }
}
.Insurance .swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}
.Insurance .swiper-pagination-bullet {
  background: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/Components/Insurance/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EAFF;IAGG,aAAA;EAGD;AACF;AAFE;EACE,UAAA;EACA,YAAA;AAIJ;AADE;EACE,kBAAA;EACA,eAAA;EACA,iCAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;AAGJ;AAFI;EARF;IASG,uBAAA;EAKH;AACF;AAFE;EACE,cAAA;EACA,WAAA;EAEA,iBAAA;AAGJ;AADE;EACE,mBAAA;AAGJ","sourcesContent":[".Insurance{\n  height: 250px;\n  @media (max-width: 980px) {\n   height: 150px;\n  }\n  .swiper {\n    width: 70%;\n    height: 100%;\n  }\n\n  .swiper-slide {\n    text-align: center;\n    font-size: 18px;\n    /* Center slide text vertically */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 180px !important;\n    @media (max-width: 980px) {\n     width: 120px !important;\n    }\n  }\n\n  .swiper-slide img {\n    display: block;\n    width: 100%;\n    //height: 100%;\n    object-fit: cover;\n  }\n  .swiper-pagination-bullet{\n    background: #ffffff;\n  }\n.item{\n\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

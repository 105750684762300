// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gallery_Gallery__PEvVA {
  width: 80%;
  margin: 28px auto;
}
.Gallery_Gallery__PEvVA .Gallery_title__Q9ILM {
  text-align: center;
}
.Gallery_Gallery__PEvVA .Gallery_title__Q9ILM h1 {
  font-family: "hemi_head";
  font-style: italic;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
}
.Gallery_Gallery__PEvVA .Gallery_title__Q9ILM h1:before {
  content: "";
  width: 80%;
  height: 1px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ED1C24;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Gallery/Gallery.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AAAI;EACE,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,qBAAA;EACA,mBAAA;AAEN;AAAM;EACE,WAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,2BAAA;EACA,yBAAA;AAER","sourcesContent":[".Gallery{\n  width: 80%;\n  margin:28px auto;\n  .title {\n    text-align: center;\n\n    h1 {\n      font-family: \"hemi_head\";\n      font-style: italic;\n      font-weight: 400;\n      font-size: 40px;\n      text-align: center;\n      letter-spacing: 0.01em;\n      color: #FFFFFF;\n      position: relative;\n      display: inline-block;\n      margin-bottom: 50px;\n\n      &:before {\n        content: \"\";\n        width: 80%;\n        height: 1px;\n        position: absolute;\n        bottom: -10px;\n        left: 50%;\n        transform: translateX(-50%);\n        background-color: #ED1C24;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Gallery": `Gallery_Gallery__PEvVA`,
	"title": `Gallery_title__Q9ILM`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import classes from "./Blog.module.scss"
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import styles from "../AutoBodyRepair/AutoBodyRepair.module.scss"
import img from "../../Assets/Images/carbonfiber.jpg";
import img2 from "../../Assets/Images/rmpaint.webp";
import img3 from "../../Assets/Images/insurance.jpg";
import img5 from "../../Assets/Images/Aluminium.jpg";

const Blog2 = () => {
    return (
        <Layout>
            <Helmet>
                <title>Signs Your Auto Body Paint Shop Did a Bad Job</title>
                <meta name="description" content={"Don't settle for subpar auto body paint work. Discover the signs of a bad job and avoid costly mistakes. Read our blog now for more information!"} />
                <script type="application/ld+json">
                    {
                        `
                    {
                        "@context": "https://schema.org",
                        "@type": "Article",
                        "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": "https://www.lawestcollision.com/signs-of-bad-body-shop-paint-job"
                    },
                        "headline": "Signs Your Auto Body Paint Shop Did a Bad Job",
                        "description": "Don't settle for subpar auto body paint work. Discover the signs of a bad job and avoid costly mistakes. Read our blog now for more information!",
                        "image": {
                        "@type":"ImageObject",
                        "url":"https://www.lawestcollision.com/static/media/rmpaint.540e2adf702e42fa40d7.webp",
                        "width":800,
                        "height":600
                        },
                        "author": {
                        "@type": "Organization",
                        "name": "LA West"
                    },
                        "publisher": {
                        "@type": "Organization",
                        "name": "La West",
                        "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.lawestcollision.com/static/media/Logo.da6001f61738b5c8f86ec197466e6b24.svg"
                    }
                    },
                        "datePublished": "2023-06-25",
                        "dateModified": "2023-06-25"
                    }
                    `
                    }
                </script>
                <link rel="canonical" href={"https://www.lawestcollision.com/signs-of-bad-body-shop-paint-job"} />
            </Helmet>

            <div className={classes.textBlog1}>
                <h1 className={classes.titleBlog}>Signs Your Auto Body Paint Shop Did a Bad Job: How to Tell if Paint is
                    Bad </h1>
            </div>
            <div className={styles.itemBlock}>
                <div>
                    <div className={styles.text1}>
                        <p>Painting a car is a delicate matter. Every car painter has so many ways to ruin all his work.
                            Auto paint defects examples are so many and everyone makes mistakes, both beginners and
                            professionals - the main thing is to draw the right conclusions from these mistakes and not
                            repeat them again. However, very often it is quite difficult to find the cause of a painting
                            error. The varnishing process alone contains more than a dozen technological operations, and
                            it is essential to follow all technical procedures. The varnishing process alone contains
                            more than a dozen technological operations, and it is essential for <a href="https://www.lawestcollision.com" target={"_blank"} style={{color:"#eee"}}><b>collision center</b></a> to
                            follow all technical procedures.</p>
                    </div>
                    <div className={styles.text2}>
                        <p>To not have a bad car paint job, you have to follow the workshop conditions (ambient and
                            surface temperature, compressor power and compressed air quality, etc.) have a great
                            influence on the painting process. In addition to violating the painting technology, errors
                            can occur due to incorrect mixing proportions of paintwork materials, or due to the use of
                            incompatible or low-quality materials.
                        </p></div>
                    <div className={styles.text1}>
                        <p>Auto paint defects examples are so different and knowing the cause of a bad car paint job
                            will help you minimize the likelihood of its occurrence, and knowing how to remove it will
                            make it possible to eliminate it with the least time and material costs.

                            So, how to check the quality of car paint after repair? We tried to describe the main
                            nuances, common car paint problems, and the procedure for performing the necessary actions
                            for bad paint jobs.
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.mainInfo} style={{marginTop: "30px"}}>
                    <div>
                        <h2>What Should You Pay Attention to When Taking a Car And How to Tell If The Paint is Bad?</h2>
                        <p>
                            When taking a car from the service, you need to make sure that you have chosen the right paint. The fact is that during the operation of the machine, the color may change, or fade. Therefore, it would be wrong to choose a color code based only on the old one. Moreover, under different lighting conditions, the color of the vehicle may change.
                        </p>
                        <p>Look at the painted elements and the details closest to them. If there are no body paint fails, then you will not see the difference. The exception will be: </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>Plastic will always be different in color from metal. This is due to the different structures of these materials.
                            </li>
                            <li> A brighter color always fades up to a week after painting. Therefore, a newly painted element will always be brighter.
                            </li>
                        </p>
                        <p>Also, auto paint defects examples are uniformity of color. You should not observe light or dark spots, or heterogeneities. All edges must be additionally varnished. However, the perfect application of paint will not yet be the key to quality work. So, if the varnish was cheap, then in the near future it will begin to peel off.</p>
                    </div>
                    <img src={img2} alt=""/>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "30px"}}>
                    <img src={img3} alt=""/>
                    <div>
                        <h2>How to Check Your Car After Painting For Color Match?</h2>
                        <p>
                            During operation, the car enamel fades, burns out, and loses its former gloss. Therefore, the selection of paint only by the code will not give the expected result, and the paint will differ from the “native” paintwork of the car. If you were warned and agreed to the selection of paint by code, be prepared for a high probability of not hitting the color.
                            </p>
                        <p>It is best if the selection of paint will take place with color correction. The colorist takes the paint code as a basis and empirically finds the optimal color recipe, which is as close as possible to the car's paintwork. Sometimes, to achieve the desired result, the colorist needs to make more than 10 colorings. And only a professional who has been trained and trained is able to choose the paint with the maximum result.
                        </p>
                        <p>In order to check whether the color is correctly selected for your car, you need to carefully consider the painted parts and the areas adjacent to them:</p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>in the shadow</li>
                            <li>in the bright sun</li>
                            <li>under artificial light;</li>
                        </p>
                        <p>If the selection of paint and painting of the car were done with high quality, with the endurance of all technical requirements and processes, you will not see any difference in the color of the car enamel.
                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo} style={{marginTop: "30px", marginBottom: "30px"}}>
                    <img src={img5} alt=""/>
                    <div>
                        <h2>Features of The Paintwork Layer at the Factory - Thickness, and Composition. </h2>
                        <p>
                            In the process of car production, the company carries out a special procedure for protecting the body from corrosion and painting using high-quality materials. All brands form the coating taking into account their proprietary features, there are a number of distinctive factors that should be remembered. Paintwork materials are applied in a fairly thin layer, which is measured in microns. </p>
                        <p>Not all potential car buyers on the free market understand why there is such hype around <a href="https://www.lawestcollision.com/car-painting" target={"_blank"} style={{color:"#eee"}}><b>car painting.</b></a> Many brands of modern cars even indicate the exact thickness of the paintwork in the car's documents. When choosing a used car with the right approach, it is imperative to examine the paintwork layer for thickening in certain places. The thing is that values ​​from the table are of great importance for some reasons:
                        </p>
                        <p style={{marginLeft: "20px", textAlign: "left"}}>
                            <li>in the factory layer of paints and varnishes, only high-quality components are used;
                            </li>
                            <li>a guarantee of the absence of putty gives hope for a long service life of metal body parts;</li>
                            <li>with any car body repair, the quality of the paint will no longer be the same as it was at the factory;</li>
                            <li>even budget brands try to paint with the highest quality to protect all details;
                            </li>
                            <li>if the thickness of the paint layer is too small or too high, problems in the operation of the car are guaranteed.</li>
                        </p>
                    </div>
                </div>
                <div className={styles.mainInfo}>
                    <img src={img} alt=""/>
                    <div>
                        <h2> How to Determine Damage With a Thickness Gauge?</h2>
                        <p>
                            On a car of any brand, it will not be difficult to conduct research using such a tool. You
                            will need the original paint thickness on the car. Write down or remember this value. Then
                            use the tool to determine the indicators of paintwork on all parts of the car. This
                            procedure is carried out in a few minutes and does not require any special skills. But it is
                            important to do everything accurately and without hesitation.

                        </p>
                        <p>The only caveat worth remembering here is the quality of the instrument. Sometimes the data
                            from the original paint thickness will not fully converge, because the thickness gauge can
                            be a little deceiving. If the deviation is within 10-15%, you can ignore such moments. But
                            exceeding the permissible values ​​at times is a guarantee of poor-quality body repairs with
                            a car. Most often, paintwork in such places can be visually distinguished from factory
                            coatings.</p>
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default Blog2;
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./style.scss";
import {Autoplay, Pagination} from "swiper";
import img1 from "../../Assets/Images/usaa.png"
import img2 from "../../Assets/Images/allstate.jpg"
import img3 from "../../Assets/Images/amica.jpg"
import img4 from "../../Assets/Images/amn.png"
import img5 from "../../Assets/Images/esurance.jpg"
import img6 from "../../Assets/Images/farmers.jpg"
import img7 from "../../Assets/Images/geico.jpg"
import img8 from "../../Assets/Images/liberty.jpg"
import img9 from "../../Assets/Images/mercury.jpg"
import img10 from "../../Assets/Images/metlife.jpg"
import img11 from "../../Assets/Images/nation.jpg"
import img12 from "../../Assets/Images/progressive.jpg"
import img13 from "../../Assets/Images/sentury.jpg"
import img14 from "../../Assets/Images/state.jpg"

const Insurance = () => {
    return (
        <div className="Insurance">
            <Swiper
                slidesPerView={5}
                spaceBetween={30}
                centeredSlides={true}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                loop={true}
                modules={[Pagination,Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide className="item"><a href="https://www.nationwide.com/" target="_blank"><img src={img11} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.usaa.com/" target="_blank"><img src={img1} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.allstate.com/" target="_blank"><img src={img2} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.amica.com/" target="_blank"><img src={img3} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.amfam.com/" target="_blank"><img src={img4} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.esurance.com/" target="_blank"><img src={img5} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.farmers.com/" target="_blank"><img src={img6} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.geico.com/" target="_blank"><img src={img7} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.libertyinsurance.ie/" target="_blank"><img src={img8} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.mercuryinsurance.com/" target="_blank"><img src={img9} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.metlife.com/" target="_blank"><img src={img10} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.progressive.com/" target="_blank"><img src={img12} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.21st.com/" target="_blank"><img src={img13} alt=""/></a></SwiperSlide>
                <SwiperSlide  className="item"><a href="https://www.statefarm.com/" target="_blank"><img src={img14} alt=""/></a></SwiperSlide>

            </Swiper>
        </div>
    );
};

export default Insurance;
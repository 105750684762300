import React, {useEffect, useState} from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import classes from "./Gallery.module.scss"
import photos from "./photos";
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";

export default function Gallery() {
    const [index, setIndex] = useState(-1);
    // useEffect(()=>{
    //     let descMeta=document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'Explore LA West gallery to see our exceptional collision repair work on various makes and models. See for yourself why we are the trusted choice in Los Angeles.')
    // },[])
    return (
        <Layout>
            <Helmet>
                <title>Gallery | LA West</title>
                <meta name="description" content={"Explore LA West gallery to see our exceptional collision repair work on various makes and models. See for yourself why we are the trusted choice in Los Angeles."} />
                <link rel="canonical" href={"https://www.lawestcollision.com/gallery"} />
            </Helmet>
            <div className={classes.Gallery}>
                <div className={classes.title}><h1>Gallery</h1></div>
                <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />
                <Lightbox
                    slides={photos}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[Fullscreen, Slideshow, Thumbnails,Zoom]}
                />
            </div>
        </Layout>
    );
}
import React from 'react';
import classes from "./Partners.module.scss"
import img1 from "../../Assets/Images/corporate31.jpg"
import img2 from "../../Assets/Images/corporate8.webp"
import img3 from "../../Assets/Images/corporate15.jpeg"
import img4 from "../../Assets/Images/kia.png"
import img5 from "../../Assets/Images/corporate12 (1).webp"
import img6 from "../../Assets/Images/nissan.png"
import img7 from "../../Assets/Images/corporate99.webp"
import img8 from "../../Assets/Images/corporate11.webp"
import img9 from "../../Assets/Images/corporate7.webp"
import img10 from "../../Assets/Images/corporate6.webp"
import img12 from "../../Assets/Images/mopar.png"
import img13 from "../../Assets/Images/tesla.png"
import img14 from "../../Assets/Images/sedgwich.png"
import img15 from "../../Assets/Images/cadical-1.png"
import img16 from "../../Assets/Images/buick-1.png"
import img17 from "../../Assets/Images/hummer.png"
import img18 from "../../Assets/Images/gm.png"
import img19 from "../../Assets/Images/gmc.png"
import img20 from "../../Assets/Images/corvette-1.png"
import img21 from "../../Assets/Images/Chevrolet.png"

const PartnersList = () => {
    return (
        <div className={classes.partners}>
            <p>LA West Collision Center proudly partners with Mercedes-Benz, Infiniti, Nissan, Kia, Hyundai, Alfa Romeo,
                Chrysler, Dodge, Fiat, Jeep, Ram, Mopar, and now also with Tesla as a Tesla Approved Collision Center.
                In addition, we are recognized as a Sedgwick Claims Management Services Direct Repair Facility,
                facilitating a streamlined claims process for our clients. These esteemed manufacturer partnerships and
                service collaborations ensure our customers receive top-quality repairs, access to genuine parts, expert
                knowledge specific to each brand, and an efficient claims management experience. Our commitment to
                excellence is reflected in our ability to deliver exceptional service and a seamless customer
                experience.
            </p>
            <div className={classes.partnersList}>
                <img className={classes.item} src={img1} alt=""/>
                <img className={classes.item} src={img13} alt=""/>
                <img className={classes.item} src={img3} alt=""/>
                <img className={classes.item} src={img6} alt=""/>
                <img className={classes.item} src={img8} alt=""/>
                <img className={classes.item} src={img4} alt=""/>
                <img className={classes.item} src={img5} alt=""/>
                <img className={classes.item} src={img10} alt=""/>
                <img className={classes.item} src={img2} alt=""/>
                <img className={classes.item} src={img7} alt=""/>
                <img className={classes.item} src={img9} alt=""/>
                <img className={classes.item} src={img12} alt=""/>
                <img className={classes.item} src={img14} alt=""/>
                <img className={classes.item} src={img15} alt=""/>
                <img className={classes.item} src={img16} alt=""/>
                <img className={classes.item} src={img17} alt=""/>
                <img className={classes.item} src={img18} alt=""/>
                <img className={classes.item} src={img19} alt=""/>
                <img className={classes.item} src={img20} alt=""/>
                <img className={classes.item} src={img21} alt=""/>
            </div>

        </div>
    );
};

export default PartnersList;
import React, {useEffect, useState} from 'react';
import './Tostify.scss'
import styles from "./ContactUs.module.scss"
import {useForm} from "react-hook-form";
import Layout from "../../Layouts/Layouts";
import {Helmet} from "react-helmet";
import {toast, ToastContainer} from "react-toastify";
import {injectStyle} from "react-toastify/inject-style";

if (typeof window !== "undefined") {
    injectStyle();
}
const ContactUs = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const {register: register2, handleSubmit: handleSubmit2, formState: {errors: errors2}} = useForm();
    const {register: register3, handleSubmit: handleSubmit3, formState: {errors: errors3}, reset: reset3} = useForm();
    const [filee,setFilee]=useState([])
    let file = []
    const handleFile = (e) => {
        let images = e.target.files;
        let i;
        for (i = 0; i < e.target.files.length; i++) {

            file.push(images[i])
        }
        setFilee((prevState)=>
            [...prevState, ...file]
        )
    }

    const handleClick = (value) => {
        const formData = new FormData();
        formData.append("name", value.name);
        formData.append("phone", value.phone);
        formData.append("email", value.email);
        formData.append("message", value.message);
        for (let i = 0; i < filee.length; i++) {
            formData.append("file", filee[i]);
        }
        fetch(`https://api.lawestcollision.com/send-email-form1`, {
            method: 'post',
            body: formData,
        });
        toast.success('Message Sent !', {
            position: toast.POSITION.TOP_RIGHT
        });
        reset();
        setFilee([])
    }
    const handleClick2 = (value) => {
        fetch(`https://api.lawestcollision.com/send-email-form2`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                firstName: value.name,
                lastName: value.surname,
                email: value.email,
                phone: value.phone,
                option: value.option
            })
        });

    }
    const handleClick3 = (value) => {
        console.log(33, value)
        fetch(`https://api.lawestcollision.com/send-email-form3`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                firstName: value.name,
                lastName: value.surname,
                email: value.email,
                phone: value.phone,
                experience: value.message
            })
        });
        toast.success('Message Sent !', {
            position: toast.POSITION.TOP_RIGHT
        });
        reset3();

    }
    // useEffect(() => {
    //     let descMeta = document.querySelector("meta[name='description']")
    //     descMeta.setAttribute("content", 'We’re here to answer any questions about our services. Call today to speak with a technician more information ☎ (818) 821-6033.')
    // }, [])
    return (
        <Layout>
            <Helmet>
                <title>Contact us | LA West</title>
                <meta name="description"
                      content="We’re here to answer any questions about our services. Call today to speak with a technician more information ☎ (818) 821-6033."/>
                <link rel="canonical" href={"https://www.lawestcollision.com/contact"} />
            </Helmet>
            <div className={styles.contact}>
                <div className={styles.container}>
                    <div className={styles.text}>
                        <h1>LET’S GET IN TOUCH</h1>
                        <h3><span>[</span> CONTACT US <span>]</span></h3>
                        <p>For LA West, customer satisfaction is the most important part of the whole process. That’s
                            why we are committed to helping you through the repair and maintenance process every step of
                            the way, where you definitely will stay pleased.

                            So if you need to reach us, call or message us. And we will serve you with love anytime</p>
                    </div>
                    <form onSubmit={handleSubmit(handleClick)} className={styles.form}>
                        <h3>Send us a message</h3>
                        <p>We’d LOVE to hear from you</p>
                        <fieldset>
                            <label htmlFor="firstName">Full Name</label>
                            <input placeholder="Alex" id="firstName" type="text" tabIndex="1" required
                                   {...register("name", {required: true})}/>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="lastName">Phone</label>
                            <input placeholder="(747) 000 - 000" id="lastName" type="number" tabIndex="3"
                                   required {...register("phone", {required: true})}/>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="email">Email</label>
                            <input placeholder="example@example.com" id="email" type="email" tabIndex="3"
                                   required {...register("email", {required: true})}/>
                        </fieldset>
                        <fieldset>
                            {/*<input className={styles.custom_file_input} type="file" onChange={handleFile}  multiple />*/}
                            <div className={styles.file_input}>
                                <input
                                    type="file"
                                    name="file-input"
                                    id="file-input"
                                    className={styles.file_input__input}
                                    onChange={handleFile}
                                    multiple

                                />
                                <label className={styles.file_input__label} htmlFor="file-input">
                                    <svg
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="upload"
                                        className={"svg-inline--fa fa-upload fa-w-16"}
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                                        ></path>
                                    </svg>
                                    <span>Upload file</span>
                                </label>
                                <span style={{
                                    color: "#ffffff",
                                    marginLeft: "10px"
                                }}>{filee.length ? filee.length + " file" : "No file chosen"}</span>
                            </div>
                        </fieldset>
                        <fieldset>
                            <label htmlFor="msg">Your Message</label>
                            <textarea placeholder="..." id="msg" tabIndex="5"
                                      required {...register("message", {required: true})}/>
                        </fieldset>
                        <fieldset style={{alignItems: "center"}}>
                            <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit
                            </button>
                        </fieldset>
                    </form>

                </div>
            </div>
            <div className={styles.line}>
            </div>
            {/*<div className={styles.contact1}>*/}
            {/*    <div className={styles.container}>*/}
            {/*        <div className={styles.text}>*/}
            {/*            <p>We are happy to ensure the safety of our customers by following all dealer protocalls and*/}
            {/*                procedures when completing repairs. LA West Collision is also happy to announce that all of*/}
            {/*                our staff is I-Car Gold certified, trained and ready to repair your vehicle and return it to*/}
            {/*                the client in the showroom condition it once was. </p>*/}
            {/*        </div>*/}
            {/*        <form onSubmit={handleSubmit2(handleClick2)} className={styles.form}>*/}
            {/*            <h3>LEAVE A REQUEST</h3>*/}
            {/*            <p>Lorem ipsum Lorem ipsum Lorem ipsum Lorem</p>*/}
            {/*            <div className={styles.blocks}>*/}
            {/*                <fieldset>*/}
            {/*                    <label htmlFor="firstName">Your name <span>*</span></label>*/}
            {/*                    <input placeholder="Alex" id="firstName" type="text" tabIndex="1" required*/}
            {/*                           autoFocus {...register2("name", {required: true})}/>*/}
            {/*                </fieldset>*/}
            {/*                <fieldset>*/}
            {/*                    <label htmlFor="lastName">Your surname <span>*</span></label>*/}
            {/*                    <input placeholder="Norman" id="lastName" type="text" tabIndex="3"*/}
            {/*                           required {...register2("surname", {required: true})}/>*/}
            {/*                </fieldset>*/}
            {/*            </div>*/}
            {/*            <div className={styles.blocks}>*/}
            {/*                <fieldset>*/}
            {/*                    <label htmlFor="email">Your mail <span>*</span></label>*/}
            {/*                    <input placeholder="example@example.com" id="email" type="email" tabIndex="3"*/}
            {/*                           required {...register2("email", {required: true})}/>*/}
            {/*                </fieldset>*/}
            {/*                <fieldset>*/}
            {/*                    <label htmlFor="phone">Your phone <span>*</span></label>*/}
            {/*                    <input placeholder="phone" id="phone" type="number" tabIndex="3"*/}
            {/*                           required {...register2("phone", {required: true})}/>*/}
            {/*                </fieldset>*/}
            {/*            </div>*/}
            {/*            <fieldset>*/}
            {/*                <label htmlFor="msg">Option <span>*</span></label>*/}
            {/*                <select name="" id="" required {...register2("option", {required: true})}>*/}
            {/*                    <option value="option1">Option</option>*/}
            {/*                </select>*/}
            {/*            </fieldset>*/}
            {/*            <fieldset style={{alignItems:"center"}}>*/}
            {/*            <button name="submit" type="submit" id="contact-submit" data-submit="...Sending"*/}
            {/*            >Submit*/}
            {/*            </button>*/}
            {/*            </fieldset>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={styles.line}>*/}
            {/*</div>*/}
            <div className={`${styles.contact1} ${styles.contact2}`}>
                <div className={styles.container}>
                    <form onSubmit={handleSubmit3(handleClick3)} className={styles.form}>
                        <h3>COOPERATION</h3>
                        <div className={styles.blocks}>
                            <fieldset>
                                <label htmlFor="firstName1">First Name <span>*</span></label>
                                <input placeholder="Alex" id="firstName1" type="text" tabIndex="1" required
                                       {...register3("name", {required: true})}/>
                            </fieldset>
                            <fieldset>
                                <label htmlFor="lastName1">Last Name <span>*</span></label>
                                <input placeholder="Norman" id="lastName1" type="text" tabIndex="3"
                                       required {...register3("surname", {required: true})}/>
                            </fieldset>
                        </div>
                        <div className={styles.blocks}>
                            <fieldset>
                                <label htmlFor="email1">Email <span>*</span></label>
                                <input placeholder="example@example.com" id="email1" type="email" tabIndex="3"
                                       required {...register3("email", {required: true})}/>
                            </fieldset>
                            <fieldset>
                                <label htmlFor="phone1">Phone <span>*</span></label>
                                <input placeholder="phone" id="phone1" type="number" tabIndex="3"
                                       required {...register3("phone", {required: true})}/>
                            </fieldset>
                        </div>
                        {/*<fieldset>*/}
                        {/*    <label htmlFor="msg1">Option <span>*</span></label>*/}
                        {/*    <select name="" id="msg1" required {...register3("option", {required: true})}>*/}
                        {/*        <option value="option1">Option</option>*/}
                        {/*    </select>*/}
                        {/*</fieldset>*/}
                        <fieldset>
                            <label htmlFor="msg11">Your Message</label>
                            <textarea placeholder="..." id="msg11" tabIndex="5"
                                      required {...register3("message", {required: true})}/>
                        </fieldset>
                        <fieldset style={{alignItems: "center"}}>
                            <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">SEND
                                MESSAGE
                            </button>
                        </fieldset>
                    </form>
                </div>
            </div>
            <div className={styles.title}>
                <h2>OUR ADDRESS</h2>
                <div className={styles.map}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3300.1046697091306!2d-118.38936222446587!3d34.19480197310357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c295d35ef228e9%3A0x2e1b8a2842cfd0a4!2s6827%20Troost%20Ave%2C%20North%20Hollywood%2C%20CA%2091605%2C%20USA!5e0!3m2!1sen!2sam!4v1683904207021!5m2!1sen!2sam"
                        width="600" height="450" style={{"border": 0}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

            </div>
            <ToastContainer/>
        </Layout>
    );
};

export default ContactUs;